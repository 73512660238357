import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import Footer from '../Footer';

const Others = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [userCurrency, setUserCurrency] = useState('KES');
  const [exchangeRate, setExchangeRate] = useState(1);
  const backendUrl = 'https://escapepln.queenlizzysgrails.com';
  const navigate = useNavigate();

  const africanCountries = [
    'Algeria', 'Angola', 'Benin', 'Botswana', 'Burkina Faso', 'Burundi', 'Cabo Verde', 'Cameroon', 'Central African Republic',
    'Chad', 'Comoros', 'Congo (Brazzaville)', 'Congo (Kinshasa)', 'Djibouti', 'Egypt', 'Equatorial Guinea', 'Eritrea', 'Eswatini',
    'Ethiopia', 'Gabon', 'Gambia', 'Ghana', 'Guinea', 'Guinea-Bissau', 'Ivory Coast', 'Kenya', 'Lesotho', 'Liberia', 'Libya',
    'Madagascar', 'Malawi', 'Mali', 'Mauritania', 'Mauritius', 'Morocco', 'Mozambique', 'Namibia', 'Niger', 'Nigeria', 'Rwanda',
    'São Tomé and Príncipe', 'Senegal', 'Seychelles', 'Sierra Leone', 'Somalia', 'South Africa', 'South Sudan', 'Sudan', 'Togo',
    'Tunisia', 'Uganda', 'Zambia', 'Zimbabwe'
  ];

  useEffect(() => {
    const getLocationAndCurrency = async () => {
      fetch('https://ipapi.co/json/')
        .then((response) => response.json())
        .then((data) => {
          const detectedCurrency = data.currency;
          setUserCurrency(detectedCurrency);
          fetchCurrencyConversion(detectedCurrency);
        })
        .catch(() => setUserCurrency('KES'));
    };

    const fetchOthers = async () => {
      try {
        const response = await fetch(`${backendUrl}/api/products?category=others`);
        const data = await response.json();
        setProducts(data);
        fetchRelatedProducts(data.map((product) => product.id));
      } catch (error) {
        console.error('Error fetching others:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchRelatedProducts = async (excludedIds) => {
      try {
        const response = await fetch(`${backendUrl}/api/products`);
        const data = await response.json();
        const filteredData = data.filter((item) => !excludedIds.includes(item.id));
        const selectedProducts = filteredData.sort(() => 0.5 - Math.random()).slice(0, 3);
        setRelatedProducts(selectedProducts);
      } catch (error) {
        console.error('Error fetching related products:', error);
      }
    };

    getLocationAndCurrency();
    fetchOthers();
  }, []);

  const fetchCurrencyConversion = (currencyCode) => {
    fetch(`https://api.exchangerate-api.com/v4/latest/KES`)
      .then((response) => response.json())
      .then((data) => {
        const conversionRate = data.rates[currencyCode] || 1;
        setExchangeRate(conversionRate);
      })
      .catch(() => setExchangeRate(1));
  };

  const isOutsideAfrica = (country) => {
    return !africanCountries.includes(country);
  };

  if (loading) return <div>Loading...</div>;

  return (
    <section className="max-w-7xl mx-auto px-4 py-8">
      <h2 className="text-4xl font-bold text-center mb-8">Others</h2>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-8">
        {products.map((product) => (
          <motion.div
            key={product.id}
            className="cursor-pointer"
            onClick={() => navigate(`/product-details/${product.id}`)}
          >
            <img
              src={`${backendUrl}/uploads/${product.imagePaths[0]}`}
              alt={product.name}
              className="w-full h-72 object-cover"
            />
            <h3 className="mt-4 text-xl font-semibold">{product.name}</h3>
            <p className="mt-1 text-lg text-gray-700">
              {userCurrency} {(Number(product.price) * (isOutsideAfrica(userCurrency) ? exchangeRate * 3 : exchangeRate)).toFixed(2)}
            </p>
          </motion.div>
        ))}
      </div>
      <div className="mt-12">
        <h2 className="text-2xl font-bold text-center mb-6">You May Also Like</h2>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-8">
          {relatedProducts.map((item) => (
            <div
              key={item.id}
              className="cursor-pointer"
              onClick={() => navigate(`/product-details/${item.id}`)}
            >
              <img
                src={`${backendUrl}/uploads/${item.imagePaths[0]}`}
                alt={item.name}
                className="w-full h-72 object-cover"
              />
              <h3 className="mt-4 text-xl font-semibold">{item.name}</h3>
              <p className="mt-1 text-lg text-gray-700">
                {userCurrency} {(Number(item.price) * (isOutsideAfrica(userCurrency) ? exchangeRate * 3 : exchangeRate)).toFixed(2)}
              </p>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default Others;
