// src/components/ViewCart.js

import React, { useEffect, useState } from 'react';
import { useCart } from '../Cart';
import { Trash2, PlusCircle, MinusCircle } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ViewCart = () => {
  const {
    cartItems,
    removeFromCart,
    clearCart,
    updateCartItemQuantity,
    exchangeRate,
    userCurrency,
  } = useCart();

  const [promoCode, setPromoCode] = useState('');
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [originalTotalCost, setOriginalTotalCost] = useState(0);

  const navigate = useNavigate();
  const backendUrl = 'https://escapepln.queenlizzysgrails.com'; // or your appropriate backend URL

  const calculateTotalCost = () => {
    const total = cartItems.reduce((sum, item) => {
      const price = item.price ? parseFloat(item.price) : 0;
      return sum + price * item.quantity;
    }, 0);

    setOriginalTotalCost(total);
    const discount = (total * discountPercentage) / 100;
    setDiscountAmount(discount);

    setTotalCost(total - discount);
  };

  useEffect(() => {
    calculateTotalCost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItems, discountPercentage]);

  const convertCurrency = (amount) => (amount * exchangeRate).toFixed(2);

  const handleApplyPromoCode = async () => {
    if (!promoCode.trim()) {
      toast.error('Please enter a promotion code.');
      return;
    }

    try {
      const response = await fetch(
        `${backendUrl}/api/promotions/validate?code=${encodeURIComponent(promoCode)}`
      );
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Invalid promotion code');
      }
      const data = await response.json();
      setDiscountPercentage(parseFloat(data.percentage));
      toast.success(`Promotion applied! You saved ${data.percentage}%`);
    } catch (error) {
      toast.error(error.message || 'Failed to apply promotion code');
      setDiscountPercentage(0);
    }
  };

  const handleProceedToCheckout = () => {
    // Directly navigate to delivery info page without login/register checks
    navigate('/delivery-info', {
      state: {
        cartItems,
        totalCost,
        userCurrency,
        discountPercentage,
        promoCode,
      },
    });
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      <div className="flex-1 overflow-y-auto">
        <div className="container mx-auto px-4 py-8 sm:py-16 font-sans w-full max-w-7xl">
          <h1 className="text-3xl font-bold mb-8 text-center lg:text-left">
            Shopping Cart
          </h1>

          {cartItems.length === 0 ? (
            <p className="text-center text-gray-500">Your cart is empty.</p>
          ) : (
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
              {/* Cart Items */}
              <div className="lg:col-span-2 space-y-6">
                {cartItems.map((item, index) => (
                  <div
                    key={index}
                    className="flex flex-col sm:flex-row items-center sm:items-start justify-between p-4 border rounded-lg shadow-sm bg-white"
                  >
                    <img
                      src={
                        item.imagePaths && item.imagePaths.length > 0
                          ? `${backendUrl}/uploads/${item.imagePaths[0]}`
                          : '/placeholder-image.jpg'
                      }
                      alt={item.name}
                      className="w-full sm:w-24 h-24 rounded-lg object-cover mb-2 sm:mb-0 sm:mr-4"
                    />
                    <div className="flex-1 w-full sm:w-auto text-center sm:text-left">
                      <p className="text-lg font-semibold text-gray-700">
                        {item.name}
                      </p>
                      <div className="flex justify-center sm:justify-start items-center mt-2">
                        <MinusCircle
                          className={`text-gray-500 cursor-pointer hover:text-gray-700 transition-colors ${
                            item.quantity <= 1 ? 'opacity-50 cursor-not-allowed' : ''
                          }`}
                          onClick={() =>
                            item.quantity > 1 &&
                            updateCartItemQuantity(item.id, item.quantity - 1)
                          }
                        />
                        <span className="mx-2 text-gray-700">{item.quantity}</span>
                        <PlusCircle
                          className="text-gray-500 cursor-pointer hover:text-gray-700 transition-colors"
                          onClick={() =>
                            updateCartItemQuantity(item.id, item.quantity + 1)
                          }
                        />
                      </div>
                    </div>
                    <div className="text-right mt-2 sm:mt-0">
                      <p className="text-lg font-semibold text-gray-700">
                        {userCurrency} {convertCurrency(item.price * item.quantity)}
                      </p>
                      <Trash2
                        className="text-red-500 cursor-pointer mt-2 mx-auto sm:mx-0 hover:text-red-700 transition-colors"
                        onClick={() => removeFromCart(item.id)}
                      />
                    </div>
                  </div>
                ))}
              </div>

              {/* Cart Summary */}
              <div className="p-6 sm:p-8 border rounded-lg shadow-lg bg-white">
                <h2 className="text-2xl font-bold text-gray-700 mb-6 text-center lg:text-left">
                  Cart Total
                </h2>

                {/* Promotion Code */}
                <div className="mb-6">
                  <label
                    htmlFor="promoCode"
                    className="block text-gray-700 font-semibold mb-2"
                  >
                    Promotion Code
                  </label>
                  <div className="flex">
                    <input
                      type="text"
                      id="promoCode"
                      value={promoCode}
                      onChange={(e) => setPromoCode(e.target.value)}
                      className="border p-2 rounded-l w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                      placeholder="Enter promotion code"
                    />
                    <button
                      onClick={handleApplyPromoCode}
                      className="bg-blue-500 text-white px-4 rounded-r hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                      Apply
                    </button>
                  </div>
                </div>

                {/* Totals */}
                <div className="mb-4 text-gray-700">
                  <p className="text-lg font-semibold flex justify-between">
                    <span>Subtotal:</span>
                    <span>
                      {userCurrency} {convertCurrency(originalTotalCost)}
                    </span>
                  </p>
                  {discountPercentage > 0 && (
                    <p className="text-lg font-semibold text-green-600 flex justify-between">
                      <span>Discount ({discountPercentage}%):</span>
                      <span>
                        - {userCurrency} {convertCurrency(discountAmount)}
                      </span>
                    </p>
                  )}
                  <p className="text-lg font-semibold flex justify-between">
                    <span>Shipping Cost:</span>
                    <span>FREE</span>
                  </p>
                </div>
                <div className="border-t pt-4 text-gray-700">
                  <p className="text-lg font-bold flex justify-between">
                    <span>Total Cost:</span>
                    <span>
                      {userCurrency} {convertCurrency(totalCost)}
                    </span>
                  </p>
                </div>

                {/* Clear Cart */}
                <div className="mt-6">
                  <button
                    onClick={() => {
                      if (
                        window.confirm(
                          'Are you sure you want to clear your cart? This action cannot be undone.'
                        )
                      ) {
                        clearCart();
                        toast.info('Cart has been cleared.');
                      }
                    }}
                    className="w-full bg-red-500 text-white py-2 rounded-md shadow hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500"
                  >
                    Clear Cart
                  </button>
                </div>

                {/* Proceed to Checkout */}
                <div className="mt-4">
                  <button
                    onClick={handleProceedToCheckout}
                    className="w-full bg-blue-600 text-white py-2 rounded-md shadow hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    Proceed to Checkout
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
    </div>
  );
};

export default ViewCart;
