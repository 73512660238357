// NewArrivals.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import Footer from '../Footer';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const NewArrivals = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [userCurrency, setUserCurrency] = useState('KES');
  const [userCountry, setUserCountry] = useState('');
  const [exchangeRate, setExchangeRate] = useState(1);
  const backendUrl = 'https://escapepln.queenlizzysgrails.com';
  const navigate = useNavigate();

  const africanCountries = [
    'Algeria', 'Angola', 'Benin', 'Botswana', 'Burkina Faso', 'Burundi',
    'Cabo Verde', 'Cameroon', 'Central African Republic', 'Chad', 'Comoros',
    'Congo (Brazzaville)', 'Congo (Kinshasa)', 'Djibouti', 'Egypt',
    'Equatorial Guinea', 'Eritrea', 'Eswatini', 'Ethiopia', 'Gabon',
    'Gambia', 'Ghana', 'Guinea', 'Guinea-Bissau', 'Ivory Coast', 'Kenya',
    'Lesotho', 'Liberia', 'Libya', 'Madagascar', 'Malawi', 'Mali',
    'Mauritania', 'Mauritius', 'Morocco', 'Mozambique', 'Namibia', 'Niger',
    'Nigeria', 'Rwanda', 'São Tomé and Príncipe', 'Senegal', 'Seychelles',
    'Sierra Leone', 'Somalia', 'South Africa', 'South Sudan', 'Sudan',
    'Togo', 'Tunisia', 'Uganda', 'Zambia', 'Zimbabwe'
  ];

  useEffect(() => {
    const getLocationAndCurrency = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        const detectedCurrency = data.currency;
        const detectedCountry = data.country_name; // Get the country name
        setUserCurrency(detectedCurrency);
        setUserCountry(detectedCountry);
        fetchCurrencyConversion(detectedCurrency);
      } catch (error) {
        console.error('Error fetching currency:', error);
        setUserCurrency('KES'); // Default currency
      }
    };

    const fetchNewArrivals = async () => {
      try {
        const response = await fetch(`${backendUrl}/api/products?limit=6&sortBy=createdAt`);
        if (!response.ok) {
          throw new Error('Failed to fetch new arrivals');
        }
        const data = await response.json();
        setProducts(data);
        fetchRelatedProducts(data.map((product) => product.id));
      } catch (error) {
        console.error('Error fetching new arrivals:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchRelatedProducts = async (excludedIds) => {
      try {
        const response = await fetch(`${backendUrl}/api/products`);
        if (!response.ok) {
          throw new Error('Failed to fetch related products');
        }
        const data = await response.json();
        const filteredData = data.filter((item) => !excludedIds.includes(item.id));
        const selectedProducts = filteredData.sort(() => 0.5 - Math.random()).slice(0, 3);
        setRelatedProducts(selectedProducts);
      } catch (error) {
        console.error('Error fetching related products:', error);
      }
    };

    getLocationAndCurrency();
    fetchNewArrivals();
  }, [backendUrl]);

  const fetchCurrencyConversion = (currencyCode) => {
    fetch(`https://api.exchangerate-api.com/v4/latest/KES`)
      .then((response) => response.json())
      .then((data) => {
        const conversionRate = data.rates[currencyCode] || 1;
        setExchangeRate(conversionRate);
      })
      .catch(() => setExchangeRate(1));
  };

  const isOutsideAfrica = (country) => {
    return !africanCountries.includes(country);
  };

  const calculatePrice = (price, isOutsideAfrica, exchangeRate) => {
    const adjustedRate = isOutsideAfrica ? exchangeRate * 3 : exchangeRate;
    return (price * adjustedRate).toFixed(2);
  };

  if (loading) return <div>Loading...</div>;

  return (
    <section className="max-w-7xl mx-auto px-4 py-8">
      <h2 className="text-4xl font-bold text-center mb-8">New Arrivals</h2>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-8">
        {products.map((product) => (
          <ProductCard
            key={product.id}
            product={product}
            backendUrl={backendUrl}
            exchangeRate={exchangeRate}
            userCurrency={userCurrency}
            isOutsideAfrica={isOutsideAfrica(userCountry)}
            calculatePrice={calculatePrice}
          />
        ))}
      </div>
      <div className="mt-12">
        <h2 className="text-2xl font-bold text-center mb-6">You May Also Like</h2>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-8">
          {relatedProducts.map((item) => (
            <ProductCard
              key={item.id}
              product={item}
              backendUrl={backendUrl}
              exchangeRate={exchangeRate}
              userCurrency={userCurrency}
              isOutsideAfrica={isOutsideAfrica(userCountry)}
              calculatePrice={calculatePrice}
            />
          ))}
        </div>
      </div>
      {/* Tooltip Component */}
      <Tooltip />
      <Footer />
    </section>
  );
};

const ProductCard = ({ product, backendUrl, exchangeRate, userCurrency, isOutsideAfrica, calculatePrice }) => {
  const [hovered, setHovered] = useState(false);
  const navigate = useNavigate();

  const images = product.imagePaths || [];
  const firstImage = images.length > 0 ? `${backendUrl}/uploads/${images[0]}` : '/placeholder-image.jpg';
  const secondImage = images.length > 1 ? `${backendUrl}/uploads/${images[1]}` : firstImage;

  const productPrice = Number(product.price);
  const convertedPrice = calculatePrice(productPrice, isOutsideAfrica, exchangeRate);

  // Handler for clicking the product card
  const handleClick = () => {
    if (!product.is_out_of_stock) {
      navigate(`/product-details/${product.id}`);
    }
  };

  return (
    <motion.div
      className={`relative flex flex-col items-center bg-zinc-800 bg-opacity-80 rounded-lg p-4 transition-transform transform ${
        product.is_out_of_stock ? 'opacity-50 cursor-not-allowed' : 'hover:scale-105 hover:shadow-lg'
      }`}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={handleClick}
      data-tooltip-id={`tooltip-${product.id}`} // Tooltip ID
      data-tooltip-content={product.is_out_of_stock ? "This product is currently out of stock." : ""}
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      {/* Product Image */}
      <motion.img
        src={hovered ? secondImage : firstImage}
        alt={product.name}
        className="w-full h-72 object-cover mb-4 rounded-md transition-transform duration-300"
        loading="lazy" // Lazy loading for performance
      />

      {/* Overlay for Out of Stock */}
      {product.is_out_of_stock && (
        <motion.div
          className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-lg"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          <img src={`${process.env.PUBLIC_URL}/assets/out-of-stock.png`} alt="Sold Out" className="w-24 h-24 object-contain" />
        </motion.div>
      )}

      {/* Product Details */}
      <h3 className="text-xl font-semibold text-white mb-1 text-center">
        {product.name}
      </h3>
      <p className="text-white text-lg">
        {userCurrency} {convertedPrice}
      </p>
    </motion.div>
  );
};

export default NewArrivals;
