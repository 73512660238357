// Shop.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import Footer from './Footer';
import { Tooltip } from 'react-tooltip'; // Import Tooltip
import 'react-tooltip/dist/react-tooltip.css'; // Import Tooltip styles

const Shop = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userCurrency, setUserCurrency] = useState('KES');
  const [userCountry, setUserCountry] = useState('');
  const [exchangeRate, setExchangeRate] = useState(1);
  const backendUrl = 'https://escapepln.queenlizzysgrails.com';
  const navigate = useNavigate();

  const africanCountries = [
    'Algeria', 'Angola', 'Benin', 'Botswana', 'Burkina Faso', 'Burundi',
    'Cabo Verde', 'Cameroon', 'Central African Republic', 'Chad', 'Comoros',
    'Congo (Brazzaville)', 'Congo (Kinshasa)', 'Djibouti', 'Egypt',
    'Equatorial Guinea', 'Eritrea', 'Eswatini', 'Ethiopia', 'Gabon',
    'Gambia', 'Ghana', 'Guinea', 'Guinea-Bissau', 'Ivory Coast', 'Kenya',
    'Lesotho', 'Liberia', 'Libya', 'Madagascar', 'Malawi', 'Mali',
    'Mauritania', 'Mauritius', 'Morocco', 'Mozambique', 'Namibia', 'Niger',
    'Nigeria', 'Rwanda', 'São Tomé and Príncipe', 'Senegal', 'Seychelles',
    'Sierra Leone', 'Somalia', 'South Africa', 'South Sudan', 'Sudan',
    'Togo', 'Tunisia', 'Uganda', 'Zambia', 'Zimbabwe'
  ];

  useEffect(() => {
    const getLocationAndCurrency = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        const detectedCurrency = data.currency;
        const detectedCountry = data.country_name; // Get the country name
        setUserCurrency(detectedCurrency);
        setUserCountry(detectedCountry);
        fetchCurrencyConversion(detectedCurrency);
      } catch (error) {
        console.error('Error fetching currency:', error);
        setUserCurrency('KES'); // Default currency
      }
    };

    const fetchProducts = async () => {
      try {
        const response = await fetch(`${backendUrl}/api/products`);
        if (!response.ok) {
          throw new Error('Failed to fetch products');
        }
        const data = await response.json();
        setProducts(data);
      } catch (error) {
        console.error('Error fetching products:', error);
      } finally {
        setLoading(false);
      }
    };

    getLocationAndCurrency();
    fetchProducts();
  }, [backendUrl]);

  const fetchCurrencyConversion = (currencyCode) => {
    fetch(`https://api.exchangerate-api.com/v4/latest/KES`)
      .then((response) => response.json())
      .then((data) => {
        const conversionRate = data.rates[currencyCode] || 1;
        setExchangeRate(conversionRate);
      })
      .catch(() => setExchangeRate(1));
  };

  const isOutsideAfrica = (country) => {
    return !africanCountries.includes(country);
  };

  const calculatePrice = (price, isOutsideAfricaFlag, exchangeRate) => {
    const adjustedRate = isOutsideAfricaFlag ? exchangeRate * 3 : exchangeRate;
    return (price * adjustedRate).toFixed(2);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen text-white bg-black">
        Loading...
      </div>
    );
  }

  return (
    <section className="mx-auto max-w-7xl px-4 py-16 bg-black">
      <h2 className="text-4xl font-bold uppercase text-center text-white mb-12">
        Shop Products
      </h2>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-x-12 gap-y-16">
        {products.map((product) => (
          <ProductCard
            key={product.id}
            product={product}
            backendUrl={backendUrl}
            exchangeRate={exchangeRate}
            userCurrency={userCurrency}
            isOutsideAfrica={isOutsideAfrica(userCountry)}
            calculatePrice={calculatePrice}
          />
        ))}
      </div>
      {/* Tooltip Component */}
      <Tooltip />
      <Footer />
    </section>
  );
};

const ProductCard = ({ product, backendUrl, exchangeRate, userCurrency, isOutsideAfrica, calculatePrice }) => {
  const [hovered, setHovered] = useState(false);
  const navigate = useNavigate();

  const images = product.imagePaths || [];
  const firstImage = images.length > 0 ? `${backendUrl}/uploads/${images[0]}` : '/placeholder-image.jpg';
  const secondImage = images.length > 1 ? `${backendUrl}/uploads/${images[1]}` : firstImage;

  const productPrice = Number(product.price);
  const convertedPrice = calculatePrice(productPrice, isOutsideAfrica, exchangeRate);

  // Handler for clicking the product card
  const handleClick = () => {
    if (!product.is_out_of_stock) {
      navigate(`/product-details/${product.id}`);
    }
  };

  return (
    <motion.div
      className={`relative flex flex-col items-center bg-black rounded-lg p-4 transition-transform transform ${
        product.is_out_of_stock ? 'opacity-50 cursor-not-allowed' : 'hover:scale-105 hover:shadow-none'
      }`}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={handleClick}
      data-tooltip-id={`tooltip-${product.id}`} // Tooltip ID
      data-tooltip-content={product.is_out_of_stock ? "This product is currently out of stock." : ""}
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      {/* Product Image */}
      <motion.img
        src={hovered ? secondImage : firstImage}
        alt={product.name}
        className="w-full h-80 object-cover mb-4 rounded-md transition-transform duration-300"
        loading="lazy" // Lazy loading for performance
      />

      

      {/* Product Details */}
      <h3 className="text-lg font-semibold text-white mb-1 text-center">
        {product.name}
      </h3>
      <p className="text-white text-sm">
        {userCurrency} {convertedPrice}
      </p>
    </motion.div>
  );
};

export default Shop;
