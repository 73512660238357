// src/context/CartProvider.js

import React, { createContext, useState, useContext, useEffect } from 'react';

// Create the Cart Context
const CartContext = createContext();

// Define the CartProvider component
export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState(() => {
    // Initialize cartItems from localStorage if available
    const storedCart = localStorage.getItem('cartItems');
    return storedCart ? JSON.parse(storedCart) : [];
  });
  
  const [exchangeRate, setExchangeRate] = useState(1);
  const [userCurrency, setUserCurrency] = useState('KES');
  const [isOutsideAfrica, setIsOutsideAfrica] = useState(false);

  // List of African Countries
  const africanCountries = [
    'Algeria', 'Angola', 'Benin', 'Botswana', 'Burkina Faso', 'Burundi', 'Cabo Verde', 'Cameroon',
    'Central African Republic', 'Chad', 'Comoros', 'Congo (Brazzaville)', 'Congo (Kinshasa)', 'Djibouti',
    'Egypt', 'Equatorial Guinea', 'Eritrea', 'Eswatini', 'Ethiopia', 'Gabon', 'Gambia', 'Ghana',
    'Guinea', 'Guinea-Bissau', 'Ivory Coast', 'Kenya', 'Lesotho', 'Liberia', 'Libya', 'Madagascar',
    'Malawi', 'Mali', 'Mauritania', 'Mauritius', 'Morocco', 'Mozambique', 'Namibia', 'Niger',
    'Nigeria', 'Rwanda', 'São Tomé and Príncipe', 'Senegal', 'Seychelles', 'Sierra Leone', 'Somalia',
    'South Africa', 'South Sudan', 'Sudan', 'Togo', 'Tunisia', 'Uganda', 'Zambia', 'Zimbabwe'
  ];

  // Save cartItems to localStorage whenever they change
  useEffect(() => {
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
  }, [cartItems]);

  // Fetch user location and adjust exchange rate
  useEffect(() => {
    const fetchCurrencyData = async () => {
      try {
        // Fetch user location data
        const response = await fetch('https://ipapi.co/json/');
        if (!response.ok) {
          throw new Error('Failed to fetch location data');
        }
        const data = await response.json();
        const detectedCurrency = data.currency || 'KES';
        const detectedCountry = data.country_name || '';
        setUserCurrency(detectedCurrency);

        // Determine if user is outside Africa
        const outsideAfrica = !africanCountries.includes(detectedCountry);
        setIsOutsideAfrica(outsideAfrica);

        // Fetch exchange rate from KES to detected currency
        const rateResponse = await fetch(`https://api.exchangerate-api.com/v4/latest/KES`);
        if (!rateResponse.ok) {
          throw new Error('Failed to fetch exchange rates');
        }
        const rateData = await rateResponse.json();
        let conversionRate = rateData.rates[detectedCurrency] || 1;

        // Adjust exchange rate if user is outside Africa
        if (outsideAfrica) {
          conversionRate *= 3;
        }

        setExchangeRate(conversionRate);
      } catch (error) {
        console.error('Error fetching currency data:', error);
        setUserCurrency('KES'); // Default currency
        setExchangeRate(1); // Default exchange rate
        setIsOutsideAfrica(false);
      }
    };

    fetchCurrencyData();
  }, []);

  // Function to add a product to the cart
  const addToCart = (product, quantity = 1) => {
    setCartItems((prevItems) => {
      const existingItem = prevItems.find(item => item.id === product.id);
      if (existingItem) {
        // If the product already exists in the cart, update its quantity
        return prevItems.map(item =>
          item.id === product.id ? { ...item, quantity: item.quantity + quantity } : item
        );
      } else {
        // If the product doesn't exist, add it to the cart
        return [...prevItems, { ...product, quantity }];
      }
    });
  };

  // Function to update the quantity of a cart item
  const updateCartItemQuantity = (productId, quantity) => {
    if (quantity < 1) {
      // If quantity is less than 1, remove the item from the cart
      removeFromCart(productId);
    } else {
      setCartItems((prevItems) =>
        prevItems.map((item) =>
          item.id === productId ? { ...item, quantity } : item
        )
      );
    }
  };

  // Function to remove an item from the cart
  const removeFromCart = (productId) => {
    setCartItems(prevItems => prevItems.filter(item => item.id !== productId));
  };

  // Function to clear the entire cart
  const clearCart = () => {
    setCartItems([]);
    localStorage.removeItem('cartItems');
  };

  return (
    <CartContext.Provider value={{ 
      cartItems, 
      addToCart, 
      updateCartItemQuantity, 
      removeFromCart, 
      clearCart, 
      exchangeRate, 
      userCurrency,
      isOutsideAfrica 
    }}>
      {children}
    </CartContext.Provider>
  );
};

// Custom hook to use the CartContext
export const useCart = () => useContext(CartContext);
