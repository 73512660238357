// src/components/LoginModal.js
import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

const LoginModal = ({ isOpen, onClose, onLoginSuccess, onOpenRegister }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  if (!isOpen) {
    return null; // Don't render anything if the modal is closed
  }

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      // Example: POST to /api/auth/login
      const response = await axios.post('https://escapepln.queenlizzysgrails.com/api/auth/login', {
        email,
        password,
      });

      if (response.status === 200) {
        // Store user data in localStorage
        localStorage.setItem('user', JSON.stringify(response.data.customer));
        toast.success('Login successful');
        onLoginSuccess();
        onClose(); // Close the login modal
      }
    } catch (error) {
      console.error('Login error:', error);
      toast.error(error.response?.data?.message || 'Login failed');
    }
  };

  // Switch to register modal
  const handleOpenRegister = () => {
    onClose(); // close login modal first
    onOpenRegister(); // open register modal
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      {/* Modal container */}
      <div className="bg-white p-6 rounded shadow-md w-full max-w-md">
        <h2 className="text-2xl font-bold mb-4">Login</h2>
        <form onSubmit={handleLogin} className="space-y-4">
          <div>
            <label className="block text-gray-700 font-semibold mb-1" htmlFor="email">
              Email
            </label>
            <input
              id="email"
              type="email"
              className="border p-2 rounded w-full"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div>
            <label className="block text-gray-700 font-semibold mb-1" htmlFor="password">
              Password
            </label>
            <input
              id="password"
              type="password"
              className="border p-2 rounded w-full"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>

          <button
            type="submit"
            className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
          >
            Log In
          </button>
        </form>
        
        {/* Register Link */}
        <div className="mt-4">
          <p className="text-sm">
            Don’t have an account?{' '}
            <button
              onClick={handleOpenRegister}
              className="text-blue-600 hover:underline"
            >
              Register
            </button>
          </p>
        </div>

        <button
          onClick={onClose}
          className="mt-2 text-gray-500 hover:text-gray-700"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default LoginModal;
