// src/components/Orders.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const user = JSON.parse(localStorage.getItem('user'));
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(`https://escapepln.queenlizzysgrails.com/api/orders/user/${user.id}`);
        if (response.data && Array.isArray(response.data.orders)) {
          setOrders(response.data.orders);
        } else {
          setOrders([]);
          setError('Failed to fetch orders.');
          toast.error('Failed to fetch orders.');
        }
      } catch (err) {
        console.error('Error fetching orders:', err);
        setError('Failed to fetch orders.');
        toast.error('Failed to fetch orders.');
      } finally {
        setLoading(false);
      }
    };

    if (user && user.id) {
      fetchOrders();
    } else {
      setLoading(false);
      setError('You must be logged in to view your orders.');
      toast.error('You must be logged in to view your orders.');
      navigate('/login');
    }
  }, [user, navigate]);

  // Determine the badge color for order status
  const getBadgeColor = (status) => {
    switch ((status || '').toLowerCase()) {
      case 'pending':
        return 'bg-yellow-200 text-yellow-800';
      case 'rejected':
        return 'bg-red-200 text-red-800';
      case 'confirmed':
        return 'bg-green-200 text-green-800';
      default:
        return 'bg-gray-200 text-gray-800';
    }
  };

  if (loading) {
    return <div className="p-4">Loading...</div>;
  }

  if (error) {
    return <div className="p-4 text-red-500">{error}</div>;
  }

  return (
    <div className="container mx-auto p-4 overflow-x-auto text-black">
      <h1 className="text-2xl font-bold mb-6">My Orders</h1>
      {orders.length === 0 ? (
        <p>You have no orders.</p>
      ) : (
        <table className="min-w-full bg-white shadow-md rounded">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b">Order ID</th>
              <th className="py-2 px-4 border-b">Status</th>
              <th className="py-2 px-4 border-b">Total (Ksh)</th>
              <th className="py-2 px-4 border-b">Created At</th>
              <th className="py-2 px-4 border-b">Cart Items</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order) => (
              <tr key={order.id} className="hover:bg-gray-100">
                <td className="py-2 px-4 border-b text-center">{order.id}</td>
                <td className="py-2 px-4 border-b text-center">
                  <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getBadgeColor(order.status)}`}>
                    {order.status}
                  </span>
                </td>
                <td className="py-2 px-4 border-b text-center">
                  {parseFloat(order.total).toLocaleString()}
                </td>
                <td className="py-2 px-4 border-b text-center">
                  {new Date(order.created_at).toLocaleString()}
                </td>
                <td className="py-2 px-4 border-b">
                  {/* Nested table for cart items */}
                  {order.cart_items && order.cart_items.length > 0 ? (
                    <table className="min-w-full bg-gray-50 text-black">
                      <thead>
                        <tr>
                          <th className="py-1 px-2 border-b text-sm">Item Name</th>
                          <th className="py-1 px-2 border-b text-sm">Quantity</th>
                          <th className="py-1 px-2 border-b text-sm">Size</th> {/* Added Size Column */}
                          <th className="py-1 px-2 border-b text-sm">Type</th> {/* Added Type Column */}
                          <th className="py-1 px-2 border-b text-sm">Price (Ksh)</th>
                          <th className="py-1 px-2 border-b text-sm">Subtotal (Ksh)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {order.cart_items.map((item, index) => (
                          <tr key={index} className="hover:bg-gray-100">
                            <td className="py-1 px-2 border-b text-sm text-center">{item.name}</td>
                            <td className="py-1 px-2 border-b text-sm text-center">{item.quantity}</td>
                            <td className="py-1 px-2 border-b text-sm text-center">
                              {item.size || 'N/A'} {/* Display Size or 'N/A' */}
                            </td>
                            <td className="py-1 px-2 border-b text-sm text-center">
                              {item.type || 'N/A'} {/* Display Type or 'N/A' */}
                            </td>
                            <td className="py-1 px-2 border-b text-sm text-center">
                              {parseFloat(item.price).toLocaleString()}
                            </td>
                            <td className="py-1 px-2 border-b text-sm text-center">
                              {(item.quantity * parseFloat(item.price)).toLocaleString()}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <p>No items found.</p>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Orders;
