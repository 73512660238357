// AudioPlayer.js

import { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';

const AudioPlayer = () => {
  const [showModal, setShowModal] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [activeAudio, setActiveAudio] = useState(null);
  const audioRef = useRef(null);
  const backendUrl = 'https://escapepln.queenlizzysgrails.com'; // Updated to localhost:8080

  useEffect(() => {
    const fetchActiveAudio = async () => {
      try {
        const response = await fetch(`${backendUrl}/api/audio`);
        if (!response.ok) throw new Error('Failed to fetch audio files.');
        const data = await response.json();
        const active = data.find(audio => audio.is_active === 1);
        setActiveAudio(active || null);
      } catch (error) {
        console.error(error);
      }
    };

    fetchActiveAudio();

    // Check localStorage for audio consent
    const audioConsent = localStorage.getItem("audioConsent");
    if (!audioConsent) {
      setShowModal(true);
    } else if (audioConsent === "accepted") {
      handlePlay();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePlay = () => {
    if (activeAudio && audioRef.current) {
      audioRef.current.src = `${backendUrl}/uploads/audio/${activeAudio.file_name}`;
      audioRef.current
        .play()
        .then(() => {
          setIsPlaying(true);
          setShowModal(false);
          localStorage.setItem("audioConsent", "accepted");
        })
        .catch((error) => {
          console.error(`Audio playback failed:`, error);
          toast.error("Failed to play audio.");
        });
    } else {
      toast.info("No active audio to play.");
      setShowModal(false);
    }
  };

  const handlePause = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleDecline = () => {
    setShowModal(false);
    localStorage.setItem("audioConsent", "declined");
  };

  return (
    <div>
      {showModal && (
        <div style={styles.modalOverlay}>
          <div style={styles.modal}>
            <h2 style={styles.modalTitle}>Audio Consent</h2>
            <p style={styles.modalMessage}>
              This website plays background audio. Would you like to listen?
            </p>
            <div style={styles.buttonContainer}>
              <button onClick={handlePlay} style={styles.playButton}>
                Yes, Play Audio
              </button>
              <button onClick={handleDecline} style={styles.declineButton}>
                No, Thanks
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Hidden audio element */}
      <audio ref={audioRef} loop />
      
      {/* Show a pause button when playing */}
      {!showModal && isPlaying && (
        <div style={styles.controlBar}>
          <button onClick={handlePause} style={styles.pauseButton}>
            Pause Audio
          </button>
        </div>
      )}
    </div>
  );
};

// Inline styles for modal and buttons
const styles = {
  modalOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  modal: {
    backgroundColor: "#fff",
    padding: "30px",
    borderRadius: "12px",
    boxShadow: "0 6px 18px rgba(0, 0, 0, 0.2)",
    textAlign: "center",
    width: "90%",
    maxWidth: "600px",
  },
  modalTitle: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    marginBottom: "15px",
  },
  modalMessage: {
    fontSize: "1rem",
    marginBottom: "25px",
    color: "#333",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-around",
  },
  playButton: {
    padding: "12px 25px",
    backgroundColor: "#28a745",
    color: "#fff",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    fontSize: "1rem",
    transition: "background-color 0.3s ease",
  },
  declineButton: {
    padding: "12px 25px",
    backgroundColor: "#dc3545",
    color: "#fff",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    fontSize: "1rem",
    transition: "background-color 0.3s ease",
  },
  controlBar: {
    position: "fixed",
    bottom: "20px",
    left: "50%",
    transform: "translateX(-50%)",
    backgroundColor: "#fff",
    padding: "10px 20px",
    borderRadius: "8px",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
    display: "flex",
    alignItems: "center",
    zIndex: 999,
  },
  pauseButton: {
    padding: "10px 20px",
    backgroundColor: "#ffc107",
    color: "#fff",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    fontSize: "1rem",
    transition: "background-color 0.3s ease",
  },
};

export default AudioPlayer;
