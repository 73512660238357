// AdminPanel.js

import React, { useState, useEffect } from "react";
import { X, UserPlus } from "react-feather";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PathFinderLoader } from "../components/PathFinderLoader";
import { useNavigate } from "react-router-dom";
import AudioSection from "../components/AudioSection"; 

const AdminPanel = () => {
  const [products, setProducts] = useState([]);
  const [carouselImages, setCarouselImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [selectedTab, setSelectedTab] = useState(1); 
  // Update your tab numbering accordingly:
  // 1 for Carousel, 2 for Products, 3 for Blogs, 4 for Users, 
  // 5 for Promotions, 6 for Audio, 7 for Customer Details, 8 for Orders

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [promotions, setPromotions] = useState([]);
  const [productFormData, setProductFormData] = useState({
    name: "",
    price: "",
    category: "",
    description: "",
    sizes: [{ size: "", quantity: "" }], // Initialize with one size-quantity pair
    imageFiles: [],
    existingImagePaths: [],
    type: "", // Initialize type
  });
  const [carouselFormData, setCarouselFormData] = useState({
    imageFile: null,
  });
  const [isSavingProduct, setIsSavingProduct] = useState(false);

  const backendUrl = "https://escapepln.queenlizzysgrails.com"; // Replace with your backend URL
  const navigate = useNavigate();

  // Authentication check
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }
  }, [navigate]);

  // Fetch initial data: products, carousel, promotions
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");

        // Fetch products
        const productsResponse = await fetch(`${backendUrl}/api/products`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!productsResponse.ok) throw new Error("Failed to fetch products");
        const productsData = await productsResponse.json();
        setProducts(productsData);

        // Fetch carousel images
        const carouselResponse = await fetch(`${backendUrl}/api/carousel`);
        if (!carouselResponse.ok)
          throw new Error("Failed to fetch carousel images");
        const carouselData = await carouselResponse.json();
        setCarouselImages(carouselData);

        // Fetch promotions
        const promotionsResponse = await fetch(
          `${backendUrl}/api/promotions`
        );
        if (!promotionsResponse.ok)
          throw new Error("Failed to fetch promotion codes");
        const promotionsData = await promotionsResponse.json();
        setPromotions(promotionsData);

      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [backendUrl]);

  // Product handlers
  const handleOpenEditModal = (product) => {
    setSelectedProduct(product);
    setProductFormData({
      name: product.name || "",
      price: product.price || "",
      category: product.category || "",
      description: product.description || "",
      sizes:
        product.sizes.length > 0 ? product.sizes : [{ size: "", quantity: "" }],
      imageFiles: [],
      existingImagePaths: product.imagePaths || [],
      type: product.type || "", // Set existing type
    });
    setIsEditModalOpen(true);
  };

  const handleOpenAddModal = () => {
    setProductFormData({
      name: "",
      price: "",
      category: "",
      description: "",
      sizes: [{ size: "", quantity: "" }], 
      imageFiles: [],
      existingImagePaths: [],
      type: "", // Initialize type
    });
    setIsAddModalOpen(true);
  };

  const handleCloseModals = () => {
    setIsEditModalOpen(false);
    setIsAddModalOpen(false);
    setSelectedProduct(null);
    setIsSavingProduct(false); 
  };

  const handleProductInputChange = (e) => {
    const { name, value } = e.target;
    setProductFormData({ ...productFormData, [name]: value });
  };

  const handleSizeQuantityChange = (index, field, value) => {
    const newSizes = [...productFormData.sizes];
    newSizes[index][field] = value;
    setProductFormData({ ...productFormData, sizes: newSizes });
  };

  const handleAddSizeQuantity = () => {
    setProductFormData({
      ...productFormData,
      sizes: [...productFormData.sizes, { size: "", quantity: "" }],
    });
  };

  const handleRemoveSizeQuantity = (index) => {
    const newSizes = [...productFormData.sizes];
    newSizes.splice(index, 1);
    setProductFormData({ ...productFormData, sizes: newSizes });
  };

  const handleProductFileChange = (e) => {
    const files = Array.from(e.target.files);
    setProductFormData({ ...productFormData, imageFiles: files });
  };

  const handleSaveProduct = async () => {
    setIsSavingProduct(true);
    try {
      const formDataObj = new FormData();
      formDataObj.append("name", productFormData.name);
      formDataObj.append("price", productFormData.price);
      formDataObj.append("category", productFormData.category);
      formDataObj.append("description", productFormData.description);
      formDataObj.append("type", productFormData.type); // Append type

      // Filter out empty size-quantity pairs
      const validSizes = productFormData.sizes.filter(
        (sq) => sq.size && sq.quantity
      );
      if (validSizes.length > 0) {
        formDataObj.append("sizes", JSON.stringify(validSizes));
      }

      productFormData.imageFiles.forEach((file) => {
        formDataObj.append("images", file);
      });

      const url = selectedProduct
        ? `${backendUrl}/api/products/update-product/${selectedProduct.id}`
        : `${backendUrl}/api/products/create-product`;

      const method = selectedProduct ? "PUT" : "POST";
      const token = localStorage.getItem("token");

      const response = await fetch(url, {
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formDataObj,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Something went wrong");
      }

      const resultMessage = await response.json();

      // Refetch updated products
      const productsResponse = await fetch(`${backendUrl}/api/products`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!productsResponse.ok) throw new Error("Failed to fetch products");
      const productsData = await productsResponse.json();
      setProducts(productsData);

      if (selectedProduct) {
        toast.success("Product updated successfully");
      } else {
        toast.success("Product added successfully");
      }

      handleCloseModals();
    } catch (error) {
      toast.error(error.message || "Failed to save the product");
      console.error(error);
      setIsSavingProduct(false); 
    }
  };

  const handleDeleteProduct = async (productId) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${backendUrl}/api/products/delete-product/${productId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to delete product");
      }

      setProducts(products.filter((product) => product.id !== productId));
      toast.success("Product deleted successfully");
    } catch (error) {
      toast.error(error.message || "Failed to delete product");
      console.error(error);
    }
  };

  // Carousel handlers (unchanged)
  const handleCarouselFileChange = (e) => {
    setCarouselFormData({ imageFile: e.target.files[0] });
  };

  const handleAddCarouselImage = async () => {
    try {
      const formDataObj = new FormData();
      formDataObj.append("image", carouselFormData.imageFile);

      const response = await fetch(`${backendUrl}/api/carousel/create-image`, {
        method: "POST",
        body: formDataObj,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to add carousel image");
      }

      const result = await response.json();
      toast.success(result.message);

      // Fetch updated carousel images
      const carouselResponse = await fetch(`${backendUrl}/api/carousel`);
      if (!carouselResponse.ok)
        throw new Error("Failed to fetch carousel images");
      const carouselData = await carouselResponse.json();
      setCarouselImages(carouselData);

      // Reset form data
      setCarouselFormData({ imageFile: null });
    } catch (error) {
      toast.error(error.message || "Failed to add carousel image");
      console.error(error);
    }
  };

  const handleDeleteCarouselImage = async (imageId) => {
    try {
      const response = await fetch(
        `${backendUrl}/api/carousel/delete-image/${imageId}`,
        {
          method: "DELETE",
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to delete carousel image");
      }

      const result = await response.json();
      toast.success(result.message);

      // Remove image from state
      setCarouselImages(carouselImages.filter((image) => image.id !== imageId));
    } catch (error) {
      toast.error(error.message || "Failed to delete carousel image");
      console.error(error);
    }
  };

  if (loading) {
    return <PathFinderLoader />;
  }

  if (error) {
    return <div className="text-center text-red-600">{`Error: ${error}`}</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8 md:py-16 font-sans">
      <ToastContainer />
      <h1 className="text-2xl md:text-3xl font-bold mb-6 md:mb-8 text-center">
        Admin Panel
      </h1>

      <TabNavigation selectedTab={selectedTab} setSelectedTab={setSelectedTab} />

      {selectedTab === 1 && (
        <CarouselSection
          carouselImages={carouselImages}
          carouselFormData={carouselFormData}
          handleCarouselFileChange={handleCarouselFileChange}
          handleAddCarouselImage={handleAddCarouselImage}
          handleDeleteCarouselImage={handleDeleteCarouselImage}
          backendUrl={backendUrl}
        />
      )}

      {selectedTab === 2 && (
        <ProductSection
          products={products}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          handleOpenEditModal={handleOpenEditModal}
          handleOpenAddModal={handleOpenAddModal}
          handleDeleteProduct={handleDeleteProduct}
          backendUrl={backendUrl}
          setProducts={setProducts} // Pass setProducts if needed
        />
      )}

      {selectedTab === 3 && <BlogSection />}

      {selectedTab === 4 && <UserSection backendUrl={backendUrl} />}

      {selectedTab === 5 && <PromotionSection backendUrl={backendUrl} />}

      {selectedTab === 6 && <AudioSection backendUrl={backendUrl} />}

      {selectedTab === 7 && <CustomerSection backendUrl={backendUrl} />}

      {selectedTab === 8 && <OrderTrackingSection backendUrl={backendUrl} />}

      {/* Product Modal */}
      {(isEditModalOpen || isAddModalOpen) && (
        <ProductModal
          isOpen={isEditModalOpen || isAddModalOpen}
          onClose={handleCloseModals}
          title={isEditModalOpen ? "Edit Product" : "Add Product"}
          formData={productFormData}
          onInputChange={handleProductInputChange}
          onFileChange={handleProductFileChange}
          onSave={handleSaveProduct}
          backendUrl={backendUrl}
          handleSizeQuantityChange={handleSizeQuantityChange}
          handleAddSizeQuantity={handleAddSizeQuantity}
          handleRemoveSizeQuantity={handleRemoveSizeQuantity}
          isSavingProduct={isSavingProduct}
        />
      )}
    </div>
  );
};

const TabNavigation = ({ selectedTab, setSelectedTab }) => {
  const tabs = [
    { id: 1, title: "Carousel" },
    { id: 2, title: "Products" },
    { id: 3, title: "Blogs" },
    { id: 4, title: "Users" },
    { id: 5, title: "Promotions" },
    { id: 6, title: "Audio" },
    { id: 7, title: "Customers" },       // NEW TAB
    { id: 8, title: "Order Tracking" },  // NEW TAB
  ];

  return (
    <div className="flex flex-wrap justify-center mb-4 md:mb-8">
      {tabs.map((tab) => (
        <button
          key={tab.id}
          onClick={() => setSelectedTab(tab.id)}
          className={`px-3 py-2 m-1 md:mx-2 font-semibold rounded ${
            selectedTab === tab.id
              ? "bg-indigo-600 text-white"
              : "bg-gray-200 text-gray-600"
          }`}
        >
          {tab.title}
        </button>
      ))}
    </div>
  );
};

// ---------------------------------------------------------------------
// SECTIONS
// ---------------------------------------------------------------------

const CarouselSection = ({
  carouselImages,
  carouselFormData,
  handleCarouselFileChange,
  handleAddCarouselImage,
  handleDeleteCarouselImage,
  backendUrl,
}) => {
  return (
    <div>
      <div className="flex flex-col md:flex-row justify-between mb-4 md:mb-6">
        <input
          type="file"
          onChange={handleCarouselFileChange}
          className="border p-2 rounded w-full md:w-auto mb-2 md:mb-0"
        />
        <button
          className="bg-orange-500 text-white py-2 px-4 rounded"
          onClick={handleAddCarouselImage}
          disabled={!carouselFormData.imageFile}
        >
          Add Carousel Image
        </button>
      </div>

      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
        {carouselImages.map((image) => (
          <div key={image.id} className="relative">
            <img
              src={`${backendUrl}/uploads/carousel/${image.image_path}`}
              alt={`Carousel ${image.id}`}
              className="w-full h-48 object-cover rounded"
            />
            <button
              className="absolute top-2 right-2 bg-red-500 text-white p-1 rounded-full"
              onClick={() => handleDeleteCarouselImage(image.id)}
            >
              <X className="w-4 h-4" />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

const ProductSection = ({
  products,
  searchQuery,
  setSearchQuery,
  handleOpenEditModal,
  handleOpenAddModal,
  handleDeleteProduct,
  backendUrl,
  setProducts, // Passed from AdminPanel
}) => {
  
  // Function to toggle stock status
  const toggleStockStatus = async (productId, currentStatus) => {
    const action = currentStatus ? "mark as In Stock" : "mark as Out of Stock";
    const confirmAction = window.confirm(`Are you sure you want to ${action} this product?`);

    if (!confirmAction) return;

    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`${backendUrl}/api/products/toggle-stock/${productId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify({ is_out_of_stock: !currentStatus }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to update stock status.");
      }

      // Update the local state without reloading
      const updatedProducts = products.map((product) => {
        if (product.id === productId) {
          return { ...product, is_out_of_stock: !currentStatus };
        }
        return product;
      });
      setProducts(updatedProducts);
      toast.success("Stock status updated successfully.");
    } catch (error) {
      toast.error(error.message || "Failed to update stock status.");
      console.error(error);
    }
  };

  return (
    <div>
      {/* Display the stock status image above the products */}
      <div className="mb-6 flex justify-center">
        <img src={`${process.env.PUBLIC_URL}/assets/out-of-stock.png`} alt="Stock Status" className="w-32 h-32 object-contain" />
      </div>

      <div className="flex flex-col md:flex-row justify-between mb-4 md:mb-6">
        <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 mb-2 md:mb-0">
          <input
            type="text"
            placeholder="Product Name..."
            className="border p-2 rounded w-full md:w-auto"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <button
          className="bg-orange-500 text-white py-2 px-4 rounded"
          onClick={handleOpenAddModal}
        >
          Add Product
        </button>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white text-black border rounded-lg">
          <thead>
            <tr>
              <th className="px-2 py-1 md:px-4 md:py-2 border">ID</th>
              <th className="px-2 py-1 md:px-4 md:py-2 border">Images</th>
              <th className="px-2 py-1 md:px-4 md:py-2 border">Product Name</th>
              <th className="px-2 py-1 md:px-4 md:py-2 border">Category</th>
              <th className="px-2 py-1 md:px-4 md:py-2 border">Price</th>
              <th className="px-2 py-1 md:px-4 md:py-2 border">Sizes</th>
              <th className="px-2 py-1 md:px-4 md:py-2 border">Type</th> {/* Added Type Column */}
              <th className="px-2 py-1 md:px-4 md:py-2 border">Stock Status</th>
              <th className="px-2 py-1 md:px-4 md:py-2 border">Created Date</th>
              <th className="px-2 py-1 md:px-4 md:py-2 border">Action</th>
            </tr>
          </thead>
          <tbody>
            {products
              .filter((product) =>
                (product.name || "")
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase())
              )
              .map((product) => (
                <tr key={product.id} className="text-sm md:text-base">
                  <td className="px-2 py-1 md:px-4 md:py-2 border">
                    {product.id}
                  </td>
                  <td className="px-2 py-1 md:px-4 md:py-2 border">
                    <div className="flex space-x-2">
                      {product.imagePaths &&
                        product.imagePaths.map((imagePath, index) => (
                          <img
                            key={index}
                            src={`${backendUrl}/uploads/${imagePath}`}
                            alt={product.name}
                            className="w-12 h-12 md:w-16 md:h-16 rounded-lg object-cover"
                          />
                        ))}
                    </div>
                  </td>
                  <td className="px-2 py-1 md:px-4 md:py-2 border">
                    {product.name}
                  </td>
                  <td className="px-2 py-1 md:px-4 md:py-2 border">
                    {product.category}
                  </td>
                  <td className="px-2 py-1 md:px-4 md:py-2 border">
                    Ksh. {parseFloat(product.price).toFixed(2)}
                  </td>
                  <td className="px-2 py-1 md:px-4 md:py-2 border">
                    {product.sizes.map((s, idx) => (
                      <div key={idx}>
                        {s.size}: {s.quantity}
                      </div>
                    ))}
                  </td>
                  <td className="px-2 py-1 md:px-4 md:py-2 border">
                    {product.type || 'N/A'} {/* Display Type or 'N/A' */}
                  </td>
                  <td className="px-2 py-1 md:px-4 md:py-2 border">
                    {product.is_out_of_stock ? (
                      <span className="text-red-500 font-semibold">Out of Stock</span>
                    ) : (
                      <span className="text-green-500 font-semibold">In Stock</span>
                    )}
                  </td>
                  <td className="px-2 py-1 md:px-4 md:py-2 border">
                    {new Date(product.created_at).toLocaleDateString()}
                  </td>
                  <td className="px-2 py-1 md:px-4 md:py-2 border">
                    <button
                      className="bg-blue-500 text-white py-1 px-2 rounded mr-2"
                      onClick={() => handleOpenEditModal(product)}
                    >
                      Edit
                    </button>
                    <button
                      className="bg-red-500 text-white py-1 px-2 rounded mr-2"
                      onClick={() => handleDeleteProduct(product.id)}
                    >
                      Delete
                    </button>
                    {!product.is_out_of_stock && (
                      <button
                        className="bg-yellow-500 text-white py-1 px-2 rounded"
                        onClick={() => toggleStockStatus(product.id, product.is_out_of_stock)}
                      >
                        Mark Out of Stock
                      </button>
                    )}
                    {product.is_out_of_stock && (
                      <button
                        className="bg-green-500 text-white py-1 px-2 rounded"
                        onClick={() => toggleStockStatus(product.id, product.is_out_of_stock)}
                      >
                        Mark In Stock
                      </button>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const BlogSection = () => (
  <div className="text-center">
    <h2 className="text-2xl font-bold mb-4">Blog Management</h2>
    <p>Blog management functionality will be added here.</p>
  </div>
);

const UserSection = ({ backendUrl }) => {
  const [users, setUsers] = useState([]);
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [newUserData, setNewUserData] = useState({
    username: "",
    password: "",
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchUsers();
  }, []);

  const openModal = (userId) => {
    setSelectedUserId(userId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedUserId(null);
    setIsModalOpen(false);
  };

  const fetchUsers = async () => {
    try {
      // const token = localStorage.getItem('token');
      const response = await fetch(`${backendUrl}/api/login/users`, {
        // headers: {
        //   'Authorization': `Bearer ${token}`
        // }
      });

      if (!response.ok) throw new Error("Failed to fetch users");
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      toast.error("Failed to fetch users");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteUser = async () => {
    try {
      const response = await fetch(
        `${backendUrl}/api/login/users/${selectedUserId}`,
        {
          method: "DELETE",
        }
      );

      if (!response.ok) throw new Error("Failed to delete user");

      toast.success("User deleted successfully");
      fetchUsers();
    } catch (error) {
      toast.error("Failed to delete user");
      console.error(error);
    } finally {
      closeModal();
    }
  };

  const handleAddUser = async (e) => {
    e.preventDefault();
    try {
      // const token = localStorage.getItem('token');
      const response = await fetch(`${backendUrl}/api/login/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // 'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(newUserData),
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || "Failed to add user");
      }

      toast.success("User added successfully");
      setIsAddUserModalOpen(false);
      setNewUserData({ username: "", password: "" });
      fetchUsers();
    } catch (error) {
      toast.error(error.message || "Failed to add user");
      console.error(error);
    }
  };

  if (loading) return <PathFinderLoader />;

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold">User Management</h2>
        <button
          onClick={() => setIsAddUserModalOpen(true)}
          className="bg-orange-500 text-white py-2 px-4 rounded flex items-center gap-2"
        >
          <UserPlus size={20} />
          Add New User
        </button>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white text-black border rounded-lg">
          <thead>
            <tr>
              <th className="px-4 py-2 border">ID</th>
              <th className="px-4 py-2 border">Username</th>
              <th className="px-4 py-2 border">Created At</th>
              <th className="px-4 py-2 border">Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.id}>
                <td className="px-4 py-2 border">{user.id}</td>
                <td className="px-4 py-2 border">{user.username}</td>
                <td className="px-4 py-2 border">
                  {new Date(user.created_at).toLocaleDateString()}
                </td>
                <td className="px-4 py-2 border">
                  <button
                    onClick={() => openModal(user.id)}
                    className="bg-red-500 text-white px-4 py-2 rounded"
                  >
                    Delete User
                  </button>

                  {/* Confirmation Modal */}
                  {isModalOpen && selectedUserId === user.id && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                      <div className="bg-white rounded-lg shadow-lg p-6 w-96">
                        <h2 className="text-xl font-semibold mb-4">
                          Confirm Deletion
                        </h2>
                        <p className="text-gray-600 mb-6">
                          Are you sure you want to delete this user? This action
                          cannot be undone.
                        </p>
                        <div className="flex justify-end space-x-4">
                          <button
                            onClick={closeModal}
                            className="bg-gray-200 text-gray-800 px-4 py-2 rounded hover:bg-gray-300"
                          >
                            Cancel
                          </button>
                          <button
                            onClick={handleDeleteUser}
                            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Add User Modal */}
      {isAddUserModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg w-full max-w-md">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-bold">Add New User</h3>
              <button
                onClick={() => setIsAddUserModalOpen(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <X size={24} />
              </button>
            </div>
            <form onSubmit={handleAddUser}>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Username
                </label>
                <input
                  type="text"
                  value={newUserData.username}
                  onChange={(e) =>
                    setNewUserData({ ...newUserData, username: e.target.value })
                  }
                  className="w-full p-2 border rounded"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Password
                </label>
                <input
                  type="password"
                  value={newUserData.password}
                  onChange={(e) =>
                    setNewUserData({
                      ...newUserData,
                      password: e.target.value,
                    })
                  }
                  className="w-full p-2 border rounded"
                  required
                />
              </div>
              <button
                type="submit"
                className="w-full bg-orange-500 text-white py-2 px-4 rounded hover:bg-orange-600"
              >
                Add User
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

const PromotionSection = ({ backendUrl }) => {
  const [promotions, setPromotions] = useState([]);
  const [isAddPromoModalOpen, setIsAddPromoModalOpen] = useState(false);
  const [newPromoData, setNewPromoData] = useState({
    code: "",
    percentage: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPromoId, setSelectedPromoId] = useState(null);

  useEffect(() => {
    fetchPromotions();
  }, []);

  const fetchPromotions = async () => {
    try {
      const response = await fetch(`${backendUrl}/api/promotions`);
      if (!response.ok) throw new Error("Failed to fetch promotions");
      const data = await response.json();
      setPromotions(data);
    } catch (error) {
      toast.error("Failed to fetch promotions");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddPromotion = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${backendUrl}/api/promotions`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newPromoData),
      });
      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || "Failed to add promotion");
      }
      toast.success("Promotion added successfully");
      setIsAddPromoModalOpen(false);
      setNewPromoData({ code: "", percentage: "" });
      fetchPromotions();
    } catch (error) {
      toast.error(error.message || "Failed to add promotion");
      console.error(error);
    }
  };

  const openDeleteModal = (promoId) => {
    setSelectedPromoId(promoId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedPromoId(null);
    setIsModalOpen(false);
  };

  const handleDeletePromotion = async () => {
    try {
      const response = await fetch(
        `${backendUrl}/api/promotions/${selectedPromoId}`,
        {
          method: "DELETE",
        }
      );
      if (!response.ok) throw new Error("Failed to delete promotion");
      toast.success("Promotion deleted successfully");
      fetchPromotions();
    } catch (error) {
      toast.error("Failed to delete promotion");
      console.error(error);
    } finally {
      closeModal();
    }
  };

  if (isLoading) return <PathFinderLoader />;

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold">Promotion Management</h2>
        <button
          onClick={() => setIsAddPromoModalOpen(true)}
          className="bg-orange-500 text-white py-2 px-4 rounded flex items-center gap-2"
        >
          Add New Promotion
        </button>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white text-black border rounded-lg">
          <thead>
            <tr>
              <th className="px-4 py-2 border">ID</th>
              <th className="px-4 py-2 border">Code</th>
              <th className="px-4 py-2 border">Percentage</th>
              <th className="px-4 py-2 border">Actions</th>
            </tr>
          </thead>
          <tbody>
            {promotions.map((promo) => (
              <tr key={promo.id}>
                <td className="px-4 py-2 border">{promo.id}</td>
                <td className="px-4 py-2 border">{promo.code}</td>
                <td className="px-4 py-2 border">{promo.percentage}%</td>
                <td className="px-4 py-2 border">
                  <button
                    onClick={() => openDeleteModal(promo.id)}
                    className="bg-red-500 text-white px-4 py-2 rounded"
                  >
                    Delete
                  </button>
                  {/* Delete Confirmation Modal */}
                  {isModalOpen && selectedPromoId === promo.id && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                      <div className="bg-white rounded-lg shadow-lg p-6 w-96">
                        <h2 className="text-xl font-semibold mb-4">
                          Confirm Deletion
                        </h2>
                        <p className="text-gray-600 mb-6">
                          Are you sure you want to delete this promotion? This
                          action cannot be undone.
                        </p>
                        <div className="flex justify-end space-x-4">
                          <button
                            onClick={closeModal}
                            className="bg-gray-200 text-gray-800 px-4 py-2 rounded hover:bg-gray-300"
                          >
                            Cancel
                          </button>
                          <button
                            onClick={handleDeletePromotion}
                            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Add Promotion Modal */}
      {isAddPromoModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg w-full max-w-md">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-bold">Add New Promotion</h3>
              <button
                onClick={() => setIsAddPromoModalOpen(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <X size={24} />
              </button>
            </div>
            <form onSubmit={handleAddPromotion}>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Code
                </label>
                <input
                  type="text"
                  value={newPromoData.code}
                  onChange={(e) =>
                    setNewPromoData({ ...newPromoData, code: e.target.value })
                  }
                  className="w-full p-2 border rounded"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Percentage
                </label>
                <input
                  type="number"
                  value={newPromoData.percentage}
                  onChange={(e) =>
                    setNewPromoData({
                      ...newPromoData,
                      percentage: e.target.value,
                    })
                  }
                  className="w-full p-2 border rounded"
                  required
                />
              </div>
              <button
                type="submit"
                className="w-full bg-orange-500 text-white py-2 px-4 rounded hover:bg-orange-600"
              >
                Add Promotion
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

// ---------------------------------------------------------------------
// NEW CustomerSection
// ---------------------------------------------------------------------
const CustomerSection = ({ backendUrl }) => {
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = async () => {
    try {
      // Adjust to your actual endpoint or query params
      const response = await fetch(`${backendUrl}/api/auth/customers`);
      if (!response.ok) {
        throw new Error("Failed to fetch customer details");
      }
      const data = await response.json();
      setCustomers(data.customers);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <PathFinderLoader />;
  if (error) {
    return (
      <div className="text-center text-red-600">{`Error: ${error}`}</div>
    );
  }

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Customer Details</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white text-black border rounded-lg">
          <thead>
            <tr>
              <th className="px-4 py-2 border">ID</th>
              <th className="px-4 py-2 border">Email</th>
              <th className="px-4 py-2 border">Created At</th>
            </tr>
          </thead>
          <tbody>
            {customers.map((customer) => (
              <tr key={customer.id}>
                <td className="px-4 py-2 border">{customer.id}</td>
                <td className="px-4 py-2 border">{customer.email}</td>
                <td className="px-4 py-2 border">
                  {new Date(customer.created_at).toLocaleDateString()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

// ---------------------------------------------------------------------
// NEW OrderTrackingSection
// ---------------------------------------------------------------------
// Badge component for displaying status
const OrderStatusBadge = ({ status }) => {
  let colorClass = "bg-gray-200 text-gray-800";
  if (status === "confirmed") {
    colorClass = "bg-green-200 text-green-800";
  } else if (status === "rejected") {
    colorClass = "bg-red-200 text-red-800";
  } else if (status === "pending") {
    colorClass = "bg-yellow-200 text-yellow-800";
  }

  return (
    <span className={`px-2 py-1 rounded-full text-sm font-semibold ${colorClass}`}>
      {status.charAt(0).toUpperCase() + status.slice(1)}
    </span>
  );
};

const OrderTrackingSection = ({ backendUrl }) => {
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [updatingOrderId, setUpdatingOrderId] = useState(null);

  useEffect(() => {
    fetchOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchOrders = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`${backendUrl}/api/orders`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch orders");
      }
      const data = await response.json();
      setOrders(data.orders);
    } catch (err) {
      setError(err.message);
      console.error("Error fetching orders:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const updateOrderStatus = async (orderId, newStatus) => {
    try {
      setUpdatingOrderId(orderId);

      const token = localStorage.getItem("token");
      if (!token) throw new Error("Unauthorized: No token provided");

      const response = await fetch(`${backendUrl}/api/orders/update-status`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ orderId, status: newStatus }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to update status");
      }

      // Update the local state
      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          order.id === orderId ? { ...order, status: newStatus } : order
        )
      );
      toast.success(`Order ${orderId} status updated to ${newStatus}`);
    } catch (err) {
      toast.error(`Error updating status: ${err.message}`);
      console.error("Error updating order status:", err);
    } finally {
      setUpdatingOrderId(null);
    }
  };

  if (isLoading) return <PathFinderLoader />;
  if (error) {
    return <div className="text-center text-red-600">{`Error: ${error}`}</div>;
  }

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Order Tracking</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white text-black border rounded-lg">
          <thead>
            <tr>
              {/* Updated headers to include Phone Number and Transaction ID */}
              <th className="px-4 py-2 border">Order ID</th>
              <th className="px-4 py-2 border">Customer Name</th>
              <th className="px-4 py-2 border">Email</th>
              <th className="px-4 py-2 border">Phone Number</th>
              <th className="px-4 py-2 border">Transaction Code</th>
              <th className="px-4 py-2 border">Status</th>
              <th className="px-4 py-2 border">Created At</th>
              <th className="px-4 py-2 border">Actions</th>
              {/* Add more columns as needed */}
            </tr>
          </thead>
          <tbody>
            {orders.map((order) => (
              <tr key={order.id}>
                <td className="px-4 py-2 border">{order.id}</td>
                <td className="px-4 py-2 border">{order.customer_name}</td>
                <td className="px-4 py-2 border">{order.email}</td>
                <td className="px-4 py-2 border">
                  {order.phone_number || "N/A"}
                </td>
                <td className="px-4 py-2 border">
                  {order.transaction_code || "N/A"}
                </td>
                <td className="px-4 py-2 border">
                  <OrderStatusBadge status={order.status} />
                </td>
                <td className="px-4 py-2 border">
                  {new Date(order.created_at).toLocaleDateString()}
                </td>
                <td className="px-4 py-2 border">
                  {order.status === "pending" ? (
                    <div className="flex space-x-2">
                      <button
                        onClick={() =>
                          updateOrderStatus(order.id, "confirmed")
                        }
                        className="bg-green-500 hover:bg-green-600 text-white px-3 py-1 rounded"
                        disabled={updatingOrderId === order.id}
                      >
                        {updatingOrderId === order.id
                          ? "Updating..."
                          : "Confirm"}
                      </button>
                      <button
                        onClick={() =>
                          updateOrderStatus(order.id, "rejected")
                        }
                        className="bg-red-500 hover:bg-red-600 text-white px-3 py-1 rounded"
                        disabled={updatingOrderId === order.id}
                      >
                        {updatingOrderId === order.id
                          ? "Updating..."
                          : "Reject"}
                      </button>
                    </div>
                  ) : (
                    <span className="text-gray-500">No actions available</span>
                  )}
                </td>
                {/* Display other relevant order details here */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

// ---------------------------------------------------------------------
// PRODUCT MODAL (unchanged except for references in main code)
// ---------------------------------------------------------------------
const ProductModal = ({
  isOpen,
  onClose,
  title,
  formData,
  onInputChange,
  onFileChange,
  onSave,
  backendUrl,
  handleSizeQuantityChange,
  handleAddSizeQuantity,
  handleRemoveSizeQuantity,
  isSavingProduct,
}) => {
  if (!isOpen) return null;

  const categories = ["tops", "bottoms", "caps", "accessories", "others"];
  const sizeOptions = ["S", "M", "L", "XL", "XXL", "XXXL", "One Size"];

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 px-4">
      <div className="bg-white w-full max-w-lg p-6 rounded-lg relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          <X className="w-6 h-6" />
        </button>
        <h2 className="text-2xl font-bold text-center mb-4">{title}</h2>
        <div className="flex flex-col space-y-4">
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={onInputChange}
            placeholder="Product Name"
            className="border p-2 rounded"
          />
          <input
            type="number"
            name="price"
            value={formData.price}
            onChange={onInputChange}
            placeholder="Price"
            className="border p-2 rounded"
          />
          <select
            name="category"
            value={formData.category}
            onChange={onInputChange}
            className="border p-2 rounded"
          >
            <option value="">Select Category</option>
            {categories.map((cat) => (
              <option key={cat} value={cat}>
                {cat.charAt(0).toUpperCase() + cat.slice(1)}
              </option>
            ))}
          </select>
          <textarea
            name="description"
            value={formData.description}
            onChange={onInputChange}
            placeholder="Description"
            className="border p-2 rounded"
          />
          <div>
            <label className="font-semibold">Sizes and Quantities:</label>
            {formData.sizes.map((sq, index) => (
              <div key={index} className="flex flex-wrap space-x-2 mt-2">
                <select
                  value={sq.size}
                  onChange={(e) =>
                    handleSizeQuantityChange(index, "size", e.target.value)
                  }
                  className="border p-2 rounded flex-1 min-w-[100px]"
                >
                  <option value="">Select Size</option>
                  {sizeOptions.map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select>
                <input
                  type="number"
                  value={sq.quantity}
                  onChange={(e) =>
                    handleSizeQuantityChange(index, "quantity", e.target.value)
                  }
                  placeholder="Quantity"
                  className="border p-2 rounded flex-1 min-w-[100px]"
                />
                <button
                  type="button"
                  onClick={() => handleRemoveSizeQuantity(index)}
                  className="text-red-500"
                >
                  Remove
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={handleAddSizeQuantity}
              className="mt-2 bg-green-500 text-white py-1 px-2 rounded"
            >
              Add Size
            </button>
          </div>
          {/* Added Type Field */}
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Type
            </label>
            <input
              type="text"
              name="type"
              value={formData.type}
              onChange={onInputChange}
              placeholder="Type (e.g., Color, Material)"
              className="border p-2 rounded w-full"
            />
          </div>
          <input
            type="file"
            onChange={onFileChange}
            className="border p-2 rounded"
            multiple
          />
          {/* Display existing images when editing */}
          {formData.existingImagePaths && formData.existingImagePaths.length > 0 && (
            <div className="flex flex-wrap">
              {formData.existingImagePaths.map((imagePath, index) => (
                <img
                  key={index}
                  src={`${backendUrl}/uploads/${imagePath}`}
                  alt="Existing"
                  className="w-24 h-24 rounded mt-2 object-cover mr-2"
                />
              ))}
            </div>
          )}
          {/* Display new image previews */}
          {formData.imageFiles && formData.imageFiles.length > 0 && (
            <div className="flex flex-wrap">
              {formData.imageFiles.map((file, index) => (
                <img
                  key={index}
                  src={URL.createObjectURL(file)}
                  alt="Preview"
                  className="w-24 h-24 rounded mt-2 object-cover mr-2"
                />
              ))}
            </div>
          )}
        </div>
        <button
          onClick={onSave}
          className={`mt-4 w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600 ${
            isSavingProduct ? "opacity-50 cursor-not-allowed" : ""
          }`}
          disabled={isSavingProduct}
        >
          {isSavingProduct ? "Saving..." : "Save"}
        </button>
      </div>
    </div>
  );
};

export default AdminPanel;
