import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./EscapePln/Navbar";
import Hero from "./EscapePln/Hero";
import AudioPlayer from "./EscapePln/components/AudioPlayer";
//import Cart from "./EscapePln/Cart";
import {
  Accessories,
  AdminPanel,
  AllBlogs,
  BlogDetail,
  Bottoms,
  Caps1,
  ContactInfo,
  DeliveryInfo,
  NewArrivals,
  Others,
  PaymentMethod,
  RefundPolicy,
  ShippingPolicy,
  Shop,
  Tops,
  ViewCart,
} from "./utils";
import Footer from "./EscapePln/Footer";
import ProductDetails from "./EscapePln/ProductDetails";
import LoginPage from "./EscapePln/AdminPanel/Login";
import PaymentStatusPage from "./EscapePln/cart/PaymentStatus";
import { CartProvider } from "./EscapePln/Cart";
import Orders from "./EscapePln/components/Orders";
import ThankYouPage from "./EscapePln/cart/Thanks";

function App() {
  return (
    <CartProvider>
    <Router>
      <div className="App">
        <AudioPlayer />
        <Navbar />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Hero />
                <Footer />
              </>
            }
          />
          <Route path="/shop" element={<Shop />} />
          <Route path="/view-cart" element={<ViewCart />} />
          <Route path="/mwenye-kiti" element={<AdminPanel />} />
          <Route path="/product-details/:id" element={<ProductDetails />} />
          <Route path="/blogs/:id" element={<BlogDetail />} />
          <Route path="/blogs" element={<AllBlogs />} />
          <Route path="/contact-info" element={<ContactInfo />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/shipping-policy" element={<ShippingPolicy />} />
          <Route path="/tops" element={<Tops />} />
          <Route path="/pants" element={<Bottoms />} />
          <Route path="/accessories" element={<Accessories />} />
          <Route path="/new" element={<NewArrivals />} />
          <Route path="/others" element={<Others />} />
          <Route path="/caps" element={<Caps1 />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/delivery-info" element={<DeliveryInfo />} />
        <Route path="/payment-method" element={<PaymentMethod />} />
        <Route path="/order-summary" element={<PaymentStatusPage />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/thank-you" element={<ThankYouPage />} />
        </Routes>
      </div>
    </Router>
    </CartProvider>
  );
}

export default App;
