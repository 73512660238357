// src/components/ThankYouPage.js

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ThankYouPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to Home after 5 seconds
    const timer = setTimeout(() => {
      navigate('/');
    }, 5000); // 5000 milliseconds = 5 seconds

    // Cleanup the timer if the component unmounts
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-zinc-950 text-white px-4">
      <h1 className="text-4xl font-bold mb-4">Thank You for Shopping!</h1>
      <p className="text-lg mb-6">
        We appreciate your trust in our brand. Your order is being processed.
      </p>
      <p className="text-sm text-gray-400">
        You will be redirected to the Home page shortly.
      </p>
    </div>
  );
};

export default ThankYouPage;
