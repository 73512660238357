// src/components/PaymentSummaryPage.js

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { motion } from 'framer-motion';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import Footer from '../Footer';

const PaymentSummaryPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { orderId } = location.state || {};

  const [orderDetails, setOrderDetails] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState('pending');

  useEffect(() => {
    // Retrieve the logged-in user from localStorage
    const storedUser = localStorage.getItem('user');
    if (!storedUser) {
      console.error('No user found in localStorage. Cannot fetch order details.');
      return;
    }

    const parsedUser = JSON.parse(storedUser);
    const userId = parsedUser.id; // Adjust if your user object is shaped differently

    // Fetch the order details
    const fetchOrderDetails = async () => {
      try {
        const response = await axios.get(
          `https://escapepln.queenlizzysgrails.com/api/orders/${userId}/${orderId}`
        );
        const { order, status } = response.data;

        // Safely parse cart_items if it's a string
        if (order && typeof order.cart_items === 'string') {
          try {
            order.cart_items = JSON.parse(order.cart_items);
          } catch (parseErr) {
            console.error('Could not parse cart_items JSON:', parseErr);
            order.cart_items = [];
          }
        }

        setOrderDetails(order);
        setPaymentStatus(status || 'pending');
      } catch (error) {
        console.error('Error fetching order details:', error);
      }
    };

    if (orderId) fetchOrderDetails();
  }, [orderId]);

  useEffect(() => {
    if (orderDetails) {
      // Navigate to Thank You page after 5 seconds
      const timer = setTimeout(() => {
        navigate('/thank-you');
      }, 5000); // 5000 milliseconds = 5 seconds

      return () => clearTimeout(timer);
    }
  }, [orderDetails, navigate]);

  if (!orderDetails) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-zinc-950">
        <p className="text-gray-300">Loading order details...</p>
      </div>
    );
  }

  // Transform the cart items as desired
  const cartItems = orderDetails.cart_items || [];
  const itemsToDisplay = cartItems.map((item) => {
    const {
      id,
      sizes,
      types, // Assuming 'types' is part of each cart item
      created_at,
      description,
      ...rest
    } = item;

    const parsedPrice = parseFloat(item.price || '0');
    const finalPrice = parsedPrice * (item.quantity || 1);

    return {
      ...rest,
      size: item.selectedSize || '',
      type: item.selectedType || 'N/A', // Ensure 'selectedType' exists in the cart item
      price: finalPrice.toFixed(2),
    };
  });

  return (
    <div className="container mx-auto px-4 py-8 bg-zinc-950 min-h-screen text-white">
      <h1 className="text-3xl font-bold mb-4">Order Summary</h1>

      <div className="mb-6 bg-black p-4 rounded">
        <p className="mb-2">
          <strong>Order ID:</strong> {orderId}
        </p>
        <p>
          <strong>Payment Status:</strong> {paymentStatus}
        </p>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-black shadow rounded-lg overflow-hidden">
          <thead className="bg-gray-800 text-gray-400 uppercase text-sm leading-normal">
            <tr>
              <th className="py-3 px-6 text-left">Item Name</th>
              <th className="py-3 px-6 text-left">Quantity</th>
              <th className="py-3 px-6 text-left">Size</th>
              <th className="py-3 px-6 text-left">Type</th> {/* Added Type Column */}
              <th className="py-3 px-6 text-left">Price (KES)</th>
            </tr>
          </thead>
          <tbody className="text-gray-200 text-sm font-light">
            {itemsToDisplay.map((item, index) => (
              <tr
                key={index}
                className="border-b border-gray-700 hover:bg-gray-800"
              >
                <td className="py-3 px-6 text-left whitespace-nowrap">
                  {item.name}
                </td>
                <td className="py-3 px-6 text-left whitespace-nowrap">
                  {item.quantity}
                </td>
                <td className="py-3 px-6 text-left whitespace-nowrap">
                  {item.size}
                </td>
                <td className="py-3 px-6 text-left whitespace-nowrap">
                  {item.type || 'N/A'} {/* Display Type or 'N/A' */}
                </td>
                <td className="py-3 px-6 text-left whitespace-nowrap">
                  {item.price}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Tooltip Component */}
      <Tooltip />

      <Footer />
    </div>
  );
};

export default PaymentSummaryPage;
