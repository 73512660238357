// AudioSection.js

import React, { useState, useEffect } from "react";
import { X } from "react-feather";
import { toast } from "react-toastify";

// Loader Component (Replace with your actual loader if different)
const PathFinderLoader = () => (
  <div className="flex justify-center items-center h-64">
    <div className="loader">Loading...</div>
  </div>
);

const AudioSection = () => { // Removed backendUrl prop
  const [audioFiles, setAudioFiles] = useState([]);
  const [isAddAudioModalOpen, setIsAddAudioModalOpen] = useState(false);
  const [newAudioData, setNewAudioData] = useState({
    title: "",
    description: "",
    audio: null,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isSettingActive, setIsSettingActive] = useState(false);

  // Set backend URL to localhost:8080
  const backendUrl = 'https://escapepln.queenlizzysgrails.com';

  useEffect(() => {
    fetchAudioFiles();
  }, []);

  const fetchAudioFiles = async () => {
    try {
      const response = await fetch(`${backendUrl}/api/audio`);
      if (!response.ok) {
        const contentType = response.headers.get("content-type");
        let errorMessage = "Failed to fetch audio files.";
        if (contentType && contentType.includes("application/json")) {
          const errorData = await response.json();
          errorMessage = errorData.message || errorMessage;
        } else {
          const errorText = await response.text();
          console.error("Non-JSON response from server:", errorText);
        }
        throw new Error(errorMessage);
      }
      const data = await response.json();
      setAudioFiles(data);
    } catch (error) {
      toast.error(error.message || "Error fetching audio files.");
      console.error("Fetch Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const allowedTypes = ["audio/mpeg", "audio/wav", "audio/ogg", "audio/mp4"];
    const maxSize = 10 * 1024 * 1024; // 10MB

    if (file) {
      if (!allowedTypes.includes(file.type)) {
        toast.error("Only mp3, wav, ogg, and m4a files are allowed.");
        return;
      }

      if (file.size > maxSize) {
        toast.error("File size exceeds the 10MB limit.");
        return;
      }

      setNewAudioData({ ...newAudioData, audio: file });
    }
  };

  const handleAddAudio = async (e) => {
    e.preventDefault();

    // Validate that an audio file is selected
    if (!newAudioData.audio) {
      toast.error("Please select an audio file to upload.");
      return;
    }

    // Create FormData object to send the audio file and metadata
    const formData = new FormData();
    formData.append("audio", newAudioData.audio);
    formData.append("title", newAudioData.title);
    formData.append("description", newAudioData.description);

    try {
      const xhr = new XMLHttpRequest();
      xhr.open("POST", `${backendUrl}/api/audio/upload`, true);

      // Track upload progress
      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentComplete = Math.round((event.loaded / event.total) * 100);
          setUploadProgress(percentComplete);
        }
      };

      xhr.onload = () => {
        if (xhr.status === 201) {
          toast.success("Audio uploaded successfully.");
          setIsAddAudioModalOpen(false);
          setNewAudioData({ title: "", description: "", audio: null });
          setUploadProgress(0);
          fetchAudioFiles();
        } else {
          const contentType = xhr.getResponseHeader("content-type");
          let errorMessage = "Failed to upload audio.";

          if (contentType && contentType.includes("application/json")) {
            const errorData = JSON.parse(xhr.responseText);
            errorMessage = errorData.message || errorMessage;
          } else {
            console.error("Non-JSON response from server:", xhr.responseText);
          }

          toast.error(errorMessage);
          setUploadProgress(0);
        }
      };

      xhr.onerror = () => {
        toast.error("Error uploading audio.");
        console.error("Upload Error:", xhr.statusText);
        setUploadProgress(0);
      };

      xhr.send(formData);
    } catch (error) {
      toast.error(error.message || "Error uploading audio.");
      console.error("Upload Error:", error);
      setUploadProgress(0);
    }
  };

  const handleDeleteAudio = async (id) => {
    if (!window.confirm("Are you sure you want to delete this audio file?")) {
      return;
    }

    setIsDeleting(true);
    try {
      const response = await fetch(`${backendUrl}/api/audio/${id}`, {
        method: "DELETE",
      });

      if (!response.ok) {
        const contentType = response.headers.get("content-type");
        let errorMessage = "Failed to delete audio.";
        if (contentType && contentType.includes("application/json")) {
          const errorData = await response.json();
          errorMessage = errorData.message || errorMessage;
        } else {
          const errorText = await response.text();
          console.error("Non-JSON response from server:", errorText);
        }
        throw new Error(errorMessage);
      }

      toast.success("Audio deleted successfully.");
      fetchAudioFiles();
    } catch (error) {
      toast.error(error.message || "Error deleting audio.");
      console.error("Delete Error:", error);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleSetActiveAudio = async (id) => {
    if (isSettingActive) return; // Prevent multiple clicks
    setIsSettingActive(true);
    try {
      const response = await fetch(`${backendUrl}/api/audio/set-active/${id}`, {
        method: "PATCH",
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Error setting active audio.");
      }
      toast.success("Active audio updated successfully.");
      fetchAudioFiles(); // Refresh the audio list to reflect the active status
    } catch (error) {
      toast.error(error.message || "Error setting active audio.");
      console.error("Set Active Error:", error);
    } finally {
      setIsSettingActive(false);
    }
  };

  if (isLoading) return <PathFinderLoader />; // Ensure PathFinderLoader is imported or replace with a loader component

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold">Audio Management</h2>
        <button
          onClick={() => setIsAddAudioModalOpen(true)}
          className="bg-green-500 text-white py-2 px-4 rounded flex items-center gap-2"
        >
          Add New Audio
        </button>
      </div>

      {/* Audio Files List */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white text-black border rounded-lg">
          <thead>
            <tr>
              <th className="px-4 py-2 border">ID</th>
              <th className="px-4 py-2 border">Title</th>
              <th className="px-4 py-2 border">Description</th>
              <th className="px-4 py-2 border">File</th>
              <th className="px-4 py-2 border">Uploaded At</th>
              <th className="px-4 py-2 border">Active</th>
              <th className="px-4 py-2 border">Actions</th>
            </tr>
          </thead>
          <tbody>
            {audioFiles.map((audio) => (
              <tr key={audio.id}>
                <td className="px-4 py-2 border">{audio.id}</td>
                <td className="px-4 py-2 border">{audio.title || "N/A"}</td>
                <td className="px-4 py-2 border">{audio.description || "N/A"}</td>
                <td className="px-4 py-2 border">
                  <audio controls>
                    <source
                      src={`https://escapepln.queenlizzysgrails.com/uploads/audio/${audio.file_name}`}
                      type="audio/mpeg"
                    />
                    Your browser does not support the audio element.
                  </audio>
                </td>
                <td className="px-4 py-2 border">
                  {new Date(audio.created_at).toLocaleDateString()}
                </td>
                <td className="px-4 py-2 border text-center">
                  {audio.is_active === 1 ? (
                    <span className="px-2 py-1 bg-blue-500 text-white rounded">Active</span>
                  ) : (
                    <span className="px-2 py-1 bg-gray-300 text-gray-700 rounded">Inactive</span>
                  )}
                </td>
                <td className="px-4 py-2 border">
                  <button
                    onClick={() => handleSetActiveAudio(audio.id)}
                    className={`${
                      audio.is_active === 1 ? "bg-blue-500 cursor-not-allowed" : "bg-gray-500 hover:bg-gray-600"
                    } text-white px-4 py-2 rounded mr-2`}
                    disabled={audio.is_active === 1 || isSettingActive}
                  >
                    {audio.is_active === 1 ? "Active" : "Set Active"}
                  </button>

                  <button
                    onClick={() => handleDeleteAudio(audio.id)}
                    className="bg-red-500 text-white px-4 py-2 rounded"
                    disabled={isDeleting}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
            {audioFiles.length === 0 && (
              <tr>
                <td colSpan="7" className="text-center py-4">
                  No audio files found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Add Audio Modal */}
      {isAddAudioModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg w-full max-w-md">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-bold">Add New Audio</h3>
              <button
                onClick={() => setIsAddAudioModalOpen(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                <X size={24} />
              </button>
            </div>
            <form onSubmit={handleAddAudio}>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Title
                </label>
                <input
                  type="text"
                  value={newAudioData.title}
                  onChange={(e) =>
                    setNewAudioData({ ...newAudioData, title: e.target.value })
                  }
                  className="w-full p-2 border rounded"
                  placeholder="Enter audio title"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Description
                </label>
                <textarea
                  value={newAudioData.description}
                  onChange={(e) =>
                    setNewAudioData({ ...newAudioData, description: e.target.value })
                  }
                  className="w-full p-2 border rounded"
                  placeholder="Enter audio description"
                ></textarea>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Select Audio File
                </label>
                <input
                  type="file"
                  accept="audio/*"
                  onChange={handleFileChange}
                  className="w-full"
                  required
                />
              </div>
              {uploadProgress > 0 && uploadProgress < 100 && (
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Upload Progress
                  </label>
                  <div className="w-full bg-gray-200 rounded-full">
                    <div
                      className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-l-full"
                      style={{ width: `${uploadProgress}%` }}
                    >
                      {uploadProgress}%
                    </div>
                  </div>
                </div>
              )}
              <button
                type="submit"
                className="w-full bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600"
                disabled={uploadProgress > 0 && uploadProgress < 100}
              >
                {uploadProgress > 0 && uploadProgress < 100
                  ? `Uploading (${uploadProgress}%)`
                  : "Upload Audio"}
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AudioSection;
