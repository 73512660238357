// src/components/ProductDetails.js

import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useCart } from './Cart';
import { motion } from 'framer-motion';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from './Footer'; // Ensure this path is correct

const ProductDetails = () => {
  const { id } = useParams();
  const { addToCart } = useCart();
  const navigate = useNavigate();

  const [product, setProduct] = useState(null);
  const [selectedSize, setSelectedSize] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [mainImageIndex, setMainImageIndex] = useState(0);
  const [userCurrency, setUserCurrency] = useState('KES');
  const [userCountry, setUserCountry] = useState('');
  const [exchangeRate, setExchangeRate] = useState(1);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const backendUrl = 'https://escapepln.queenlizzysgrails.com'; 

  // Define isOutsideAfrica before usage
  const africanCountries = [
    'Algeria', 'Angola', 'Benin', 'Botswana', 'Burkina Faso', 'Burundi', 'Cabo Verde', 'Cameroon', 'Central African Republic',
    'Chad', 'Comoros', 'Congo (Brazzaville)', 'Congo (Kinshasa)', 'Djibouti', 'Egypt', 'Equatorial Guinea', 'Eritrea', 'Eswatini',
    'Ethiopia', 'Gabon', 'Gambia', 'Ghana', 'Guinea', 'Guinea-Bissau', 'Ivory Coast', 'Kenya', 'Lesotho', 'Liberia', 'Libya',
    'Madagascar', 'Malawi', 'Mali', 'Mauritania', 'Mauritius', 'Morocco', 'Mozambique', 'Namibia', 'Niger', 'Nigeria', 'Rwanda',
    'São Tomé and Príncipe', 'Senegal', 'Seychelles', 'Sierra Leone', 'Somalia', 'South Africa', 'South Sudan', 'Sudan', 'Togo',
    'Tunisia', 'Uganda', 'Zambia', 'Zimbabwe'
  ];

  // Function to check if a country is outside Africa
  const isOutsideAfrica = useCallback((country) => {
    return !africanCountries.includes(country);
  }, [africanCountries]);

  // Fetch Product Details
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await fetch(`${backendUrl}/api/products/${id}`);
        if (!response.ok) throw new Error('Failed to fetch product');
        const data = await response.json();
        setProduct(data);
        fetchRelatedProducts(data.category);
      } catch (error) {
        console.error('Error fetching product:', error);
        toast.error('Failed to fetch product');
      }
    };
    fetchProduct();
  }, [id, backendUrl]);

  // Fetch User Location and Currency
  useEffect(() => {
    const fetchUserLocation = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/');
        if (!response.ok) throw new Error('Failed to fetch user location');
        const data = await response.json();
        const detectedCurrency = data.currency || 'KES';
        const detectedCountry = data.country_name || 'Kenya';
        setUserCurrency(detectedCurrency);
        setUserCountry(detectedCountry);
        fetchCurrencyConversion(detectedCurrency);
      } catch (error) {
        console.error('Error fetching user location:', error);
        setUserCurrency('KES');
      }
    };
    fetchUserLocation();
  }, []);

  // Fetch Currency Conversion Rate
  const fetchCurrencyConversion = useCallback((currencyCode) => {
    if (currencyCode === 'KES') {
      setExchangeRate(1);
      return;
    }
    fetch(`https://api.exchangerate-api.com/v4/latest/KES`)
      .then((response) => {
        if (!response.ok) throw new Error('Failed to fetch exchange rates');
        return response.json();
      })
      .then((data) => {
        const conversionRate = data.rates[currencyCode] || 1;
        setExchangeRate(conversionRate);
      })
      .catch((error) => {
        console.error('Error fetching exchange rates:', error);
        setExchangeRate(1);
      });
  }, []);

  // Fetch Related Products
  const fetchRelatedProducts = useCallback(async (category) => {
    try {
      const response = await fetch(`${backendUrl}/api/products?category=${encodeURIComponent(category)}`);
      if (!response.ok) throw new Error('Failed to fetch related products');
      const data = await response.json();
      const filteredData = data.filter((item) => item.id !== parseInt(id, 10));
      const selectedProducts = filteredData.sort(() => 0.5 - Math.random()).slice(0, 3);
      setRelatedProducts(selectedProducts);
    } catch (error) {
      console.error('Error fetching related products:', error);
    }
  }, [backendUrl, id]);

  // Image Slider Effect
  useEffect(() => {
    if (product && product.imagePaths && product.imagePaths.length > 1) {
      const interval = setInterval(() => {
        setMainImageIndex((prevIndex) => (prevIndex + 1) % product.imagePaths.length);
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [product]);

  if (!product) return <div className="text-center mt-20 text-white">Loading...</div>;

  // Calculate Prices
  const productPrice = product.price ? Number(product.price) : 0;
  const baseConvertedPrice = (productPrice * exchangeRate).toFixed(2);
  const finalConvertedPrice = isOutsideAfrica(userCountry)
    ? (productPrice * exchangeRate * 3).toFixed(2)
    : baseConvertedPrice;
  const images = product.imagePaths || [];
  const sizes = product.sizes || [];
  const types = product.types || []; // Assuming `types` is an array similar to `sizes`

  // Handle Add to Cart
  const handleAddToCart = () => {
    if (sizes.length > 0 && !selectedSize) {
      toast.error('Please select a size');
      return;
    }
    if (types.length > 0 && !selectedType) {
      toast.error('Please select a type');
      return;
    }
    const productToAdd = { ...product, selectedSize, selectedType };
    addToCart(productToAdd);
    toast.success('Product added to cart');
  };

  return (
    <div className="container mx-auto px-4 py-12 text-white">
      <ToastContainer />
      <div className="flex flex-col md:flex-row">
        {/* Left: Images Section */}
        <div className="md:w-2/3">
          <motion.div
            className="relative mb-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <img
              src={`${backendUrl}/uploads/${images[mainImageIndex] || 'placeholder-image.jpg'}`}
              alt={product.name}
              className="w-full h-96 object-contain rounded"
            />
          </motion.div>

          {/* Thumbnails */}
          <div className="flex mt-4 space-x-2 overflow-x-auto">
            {images.map((img, index) => (
              <img
                key={index}
                src={`${backendUrl}/uploads/${img}`}
                alt={`Thumbnail ${index + 1}`}
                className={`w-20 h-20 object-contain rounded cursor-pointer transition-all duration-300 ${
                  mainImageIndex === index ? 'border-2 border-white' : 'opacity-70'
                }`}
                onClick={() => setMainImageIndex(index)}
              />
            ))}
          </div>
        </div>

        {/* Right: Product Info Section */}
        <div className="md:w-1/3 md:pl-8 mt-8 md:mt-0 relative">
          {/* Out of Stock Overlay */}
          {product.is_out_of_stock && (
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-lg mb-4">
              <img src={`${process.env.PUBLIC_URL}/assets/out-of-stock.png`} alt="Sold Out" className="w-24 h-24 object-contain" />
            </div>
          )}

          <h1 className="text-3xl font-bold mb-4">{product.name}</h1>
          <p className="text-lg mb-4">{product.description}</p>
          <p className="text-2xl font-semibold mb-4">
            {userCurrency} {finalConvertedPrice}
          </p>

          {/* Size Selection */}
          {sizes.length > 0 && (
            <div className="mb-6">
              <label className="block mb-2 font-medium">Select Size:</label>
              <div className="flex flex-wrap gap-2">
                {sizes.map((sizeObj, index) => (
                  <button
                    key={index}
                    type="button"
                    className={`px-4 py-2 rounded border transition ${
                      selectedSize === sizeObj.size
                        ? 'bg-white text-black'
                        : 'bg-black text-white border-white'
                    }`}
                    onClick={() => setSelectedSize(sizeObj.size)}
                  >
                    {sizeObj.size}
                  </button>
                ))}
              </div>
            </div>
          )}

          {/* Type Selection */}
          {types.length > 0 && (
            <div className="mb-6">
              <label className="block mb-2 font-medium">Select Type:</label>
              <div className="flex flex-wrap gap-2">
                {types.map((typeObj, index) => (
                  <button
                    key={index}
                    type="button"
                    className={`px-4 py-2 rounded border transition ${
                      selectedType === typeObj.type
                        ? 'bg-white text-black'
                        : 'bg-black text-white border-white'
                    }`}
                    onClick={() => setSelectedType(typeObj.type)}
                  >
                    {typeObj.type}
                  </button>
                ))}
              </div>
            </div>
          )}

          {/* Action Buttons */}
          <div className="flex flex-col space-y-4">
            <button
              type="button"
              className={`w-full px-6 py-3 rounded text-white ${
                (sizes.length > 0 && !selectedSize) ||
                (types.length > 0 && !selectedType) ||
                product.is_out_of_stock
                  ? 'bg-gray-600 cursor-not-allowed'
                  : 'bg-black hover:bg-gray-800'
              }`}
              onClick={handleAddToCart}
              disabled={
                (sizes.length > 0 && !selectedSize) ||
                (types.length > 0 && !selectedType) ||
                product.is_out_of_stock
              }
            >
              Add to Cart
            </button>
          </div>
        </div>
      </div>

      {/* Related Products */}
      <div className="mt-12">
        <h2 className="text-2xl font-bold text-center mb-6">You May Also Like</h2>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-8">
          {relatedProducts.map((item) => {
            const relatedPrice = (Number(item.price) * exchangeRate * (isOutsideAfrica(userCountry) ? 3 : 1)).toFixed(2);
            return (
              <div
                key={item.id}
                className={`cursor-pointer relative ${item.is_out_of_stock ? 'opacity-50 cursor-not-allowed' : 'hover:scale-105 transition-transform'}`}
                onClick={() => {
                  if (!item.is_out_of_stock) {
                    navigate(`/product-details/${item.id}`);
                  }
                }}
              >
                {/* Out of Stock Overlay */}
                {item.is_out_of_stock && (
                  <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-lg">
                    <img src={`${process.env.PUBLIC_URL}/assets/out-of-stock.png`} alt="Sold Out" className="w-16 h-16 object-contain" />
                  </div>
                )}
                <img
                  src={`${backendUrl}/uploads/${item.imagePaths[0] || 'placeholder-image.jpg'}`}
                  alt={item.name}
                  className="w-full h-72 object-contain rounded mb-2"
                />
                <h3 className="text-lg font-medium">{item.name}</h3>
                <p>
                  {userCurrency} {relatedPrice}
                </p>
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProductDetails;
