// PaymentPage.js

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PaymentPage = () => {
  const { state } = useLocation();
  const { orderId, cartItems, totalCost, phoneNumber } = state || {};

  const navigate = useNavigate();

  // Inputs for the user to fill
  const [transactionCode, setTransactionCode] = useState('');
  const [amount, setAmount] = useState(totalCost || '');
  const [phoneNumberState, setPhoneNumberState] = useState(phoneNumber || '');

  // Load payment details from localStorage if they exist
  useEffect(() => {
    const savedPayment = localStorage.getItem(`payment_${orderId}`);
    if (savedPayment) {
      const { transactionCode, amount, phoneNumber } = JSON.parse(savedPayment);
      setTransactionCode(transactionCode);
      setAmount(amount);
      setPhoneNumberState(phoneNumber);
    }
  }, [orderId]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!transactionCode || !amount) {
      toast.error('Please fill in the transaction code and amount.');
      return;
    }

    try {
      // POST data to the backend for Pochi la Biashara
      const response = await axios.post('https://escapepln.queenlizzysgrails.com/api/payments/pochi-labiashara', {
        orderId,
        phoneNumber: phoneNumberState,
        transactionCode,
        amount
      });

      // If successful, store payment details in localStorage
      if (response.status === 201) {
        const paymentDetails = { transactionCode, amount, phoneNumber: phoneNumberState };
        localStorage.setItem(`payment_${orderId}`, JSON.stringify(paymentDetails));

        toast.success(response.data.message || 'Payment details recorded successfully.');

        // Navigate to PaymentSummaryPage after a short delay to allow the toast to display
        setTimeout(() => {
          navigate('/order-summary', {
            state: {
              orderId,
              // You can pass these along so PaymentSummaryPage can fetch or display data
            },
          });
        }, 2000);
      }
    } catch (error) {
      console.error('Error submitting payment details:', error);
      toast.error('An error occurred while submitting payment details.');
    }
  };

  return (
    <div className="container mx-auto px-4 py-8 font-sans">
      {/* Instructions and disclaimers */}
      <div className="bg-gray-100 p-4 rounded-md mb-6">
        <h1 className="text-2xl font-bold mb-2">How to Pay Using Pochi la Biashara</h1>
        <ol className="list-decimal ml-5 mb-4 text-gray-700">
          <li>Open your M-PESA menu on your phone.</li>
          <li>Select “Pochi La Biashara”.</li>
          <p className="text-gray-700 mb-2">
          Please pay using <strong>Pochi Labiashara</strong> to the number:
        </p>
        <p className="text-lg font-bold text-green-600 mb-2">0712345678</p>
          <p className="text-sm text-gray-500">
          After you pay, you will receive a transaction code from MPESA. Enter
          that code below along with the amount you paid. Your order will remain
          in <strong>pending</strong> status until verified.
        </p>
          <li>Enter the amount to pay.</li>
          <li>Confirm the details and complete the payment.</li>
        </ol>
        <p className="text-sm text-red-600 font-semibold">
          Disclaimer: Please note that your order will remain “Pending” until we verify your payment. 
          In case of any delays or issues, contact support.
        </p>
      </div>

      <form onSubmit={handleSubmit} className="max-w-md mx-auto space-y-4">
        <div>
          <label className="block text-gray-700 font-semibold mb-1" htmlFor="phoneNumber">
            Phone Number (Pochi La Biashara)
          </label>
          <input
            type="text"
            id="phoneNumber"
            className="w-full p-2 border rounded"
            value={phoneNumberState}
            onChange={(e) => setPhoneNumberState(e.target.value)}
            required
          />
        </div>

        <div>
          <label className="block text-gray-700 font-semibold mb-1" htmlFor="transactionCode">
            Transaction Code
          </label>
          <input
            type="text"
            id="transactionCode"
            className="w-full p-2 border rounded"
            placeholder="Enter the M-PESA confirmation code"
            value={transactionCode}
            onChange={(e) => setTransactionCode(e.target.value)}
            required
          />
        </div>

        <div>
          <label className="block text-gray-700 font-semibold mb-1" htmlFor="amount">
            Amount (KES)
          </label>
          <input
            type="number"
            id="amount"
            className="w-full p-2 border rounded"
            placeholder="Enter the amount you sent"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            required
            min="0"
          />
        </div>

        <button
          type="submit"
          className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
        >
          Submit Payment
        </button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default PaymentPage;
