// src/components/Navbar.js
import React, { useState, useEffect, useRef } from "react";
import {
  ShoppingCart,
  X,
  Menu,
  User,
  MinusCircle,
  PlusCircle,
  Trash,
} from "react-feather";
import { useCart } from "./Cart";
import { NavLink, useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import LoginModal from "./components/customer-details/CustomerLogin"; // Adjust the path as necessary
import RegisterModal from "./components/customer-details/CustomerRegister"; 
import { toast } from "react-toastify"; // Ensure react-toastify is installed


const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [cartOpen, setCartOpen] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
  const { cartItems, removeFromCart, clearCart, updateCartItemQuantity } = useCart();
  const cartRef = useRef(null);
  const menuRef = useRef(null);
  const userMenuRef = useRef(null); // Ref for user menu
  const [iconBounce, setIconBounce] = useState(false);
  const navigate = useNavigate();

  // State to manage user authentication
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Check localStorage for user data on mount
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cartRef.current && !cartRef.current.contains(event.target)) {
        setCartOpen(false);
      }
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
      if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
        setIsUserMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [cartRef, menuRef, userMenuRef]);

  useEffect(() => {
    if (cartItems.length > 0) {
      setIconBounce(true);
      const timeout = setTimeout(() => setIconBounce(false), 500);
      return () => clearTimeout(timeout);
    }
  }, [cartItems]);

  const handleCheckout = () => {
    if (cartItems.length === 0) return;
    const message = cartItems
      .map(
        (item) => `${item.quantity} x ${item.itemName} at ${item.retailPrice}`
      )
      .join(", ");
    const whatsappUrl = `https://wa.me/254101757541?text=I am interested in: ${encodeURIComponent(
      message
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  const handleViewCart = () => {
    navigate("/view-cart");
  };

  const toggleMenu = () => setIsOpen(!isOpen);

  // Handle user icon click
  const toggleUserMenu = () => setIsUserMenuOpen(!isUserMenuOpen);

  // Handle logout
  const handleLogout = () => {
    localStorage.removeItem("user");
    setUser(null);
    setIsUserMenuOpen(false);
    toast.info("Logged out successfully.");
    navigate("/"); // Redirect to home or any other page
  };

  // Handle Track Your Order
  const handleTrackOrder = () => {
    if (!user) {
      // Prompt login if not authenticated
      setIsLoginModalOpen(true);
      setIsUserMenuOpen(false);
    } else {
      // Navigate to orders page
      navigate("/orders");
      setIsUserMenuOpen(false);
    }
  };

  // Called after successful login
  const onLoginSuccess = () => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
    setIsLoginModalOpen(false);
    // Optionally, navigate to orders if login was prompted by track order
    navigate("/orders");
  };

  // Toggle from login to register
  const onOpenRegister = () => {
    setIsRegisterModalOpen(true);
    setIsLoginModalOpen(false);
  };
  // Toggle from register to login
  const onOpenLogin = () => {
    setIsLoginModalOpen(true);
    setIsRegisterModalOpen(false);
  };

  return (
    <nav className="sticky top-0 bg-black shadow-md w-full z-10">
      {/* Main Navbar */}
      <div className="container mx-auto flex items-center justify-between p-4 relative">
        {/* Video as Logo */}
        <NavLink to="/" className="flex items-center">
          <video
            className="h-28 w-auto md:hidden" // Visible only on mobile
            src={process.env.PUBLIC_URL + "/assets/logo.mp4"}
            autoPlay
            loop
            muted
            playsInline
            disablePictureInPicture
            // Prevent user interactions with the video
            style={{ pointerEvents: "none" }}
          />
          <video
            className="hidden md:block h-28 w-auto" // Visible only on desktop
            src={process.env.PUBLIC_URL + "/assets/logo.mp4"}
            autoPlay
            loop
            muted
            playsInline
            disablePictureInPicture
            // Prevent user interactions with the video
            style={{ pointerEvents: "none" }}
          />
        </NavLink>

        {/* Hamburger Menu and Icons (Mobile) */}
        <div className="md:hidden flex items-center space-x-4">
          {/* Hamburger Menu */}
          <Menu
            className="text-xl text-white cursor-pointer"
            onClick={toggleMenu}
          />

          {/* Shopping Cart */}
          <div className="relative" onClick={handleViewCart}>
            <motion.div
              animate={iconBounce ? { scale: [1, 1.5, 1] } : {}}
              transition={{ duration: 0.5 }}
              className="inline-block"
            >
              <ShoppingCart className="text-xl text-white cursor-pointer" />
            </motion.div>
            {cartItems.length > 0 && (
              <span className="absolute top-[-5px] right-[-8px] bg-red-600 text-white rounded-full h-5 w-5 flex items-center justify-center text-xs">
                {cartItems.length}
              </span>
            )}
          </div>

          {/* User Icon (Mobile) */}
          {/* <div className="relative" ref={userMenuRef}>
            <User
              className="text-xl text-white cursor-pointer"
              onClick={toggleUserMenu}
            />
            <AnimatePresence>
              {isUserMenuOpen && (
                <motion.div
                  className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-20"
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                >
                  {!user ? (
                    <>
                      <button
                        onClick={() => {
                          setIsLoginModalOpen(true);
                          setIsUserMenuOpen(false);
                        }}
                        className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100"
                      >
                        Login
                      </button>
                      {/* You can add Register option here if desired */}
                      {/* <button
                        onClick={handleTrackOrder}
                        className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100"
                      >
                        Track Your Order
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        onClick={handleTrackOrder}
                        className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100"
                      >
                        Track Your Order
                      </button>
                      <button
                        onClick={handleLogout}
                        className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100"
                      >
                        Logout
                      </button>
                    </>
                  )}
                </motion.div>
              )}
            </AnimatePresence>
          </div> */}
           
        </div>

        {/* Links and Cart (Desktop) */}
        <div className="hidden md:flex items-center space-x-4">
          {/* Shopping Cart */}
          <div className="relative" onClick={handleViewCart}>
            <motion.div
              animate={iconBounce ? { scale: [1, 1.5, 1] } : {}}
              transition={{ duration: 0.5 }}
              className="inline-block"
            >
              <ShoppingCart className="text-xl text-white cursor-pointer" />
            </motion.div>
            {cartItems.length > 0 && (
              <span className="absolute top-[-5px] right-[-8px] bg-red-600 text-white rounded-full h-5 w-5 flex items-center justify-center text-xs">
                {cartItems.length}
              </span>
            )}
          </div>

          {/* User Icon (Desktop) */}
          {/* <div className="relative" ref={userMenuRef}>
            <User
              className="text-xl text-white cursor-pointer"
              onClick={toggleUserMenu}
            />
            <AnimatePresence>
              {isUserMenuOpen && (
                <motion.div
                  className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-20"
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                >
                  {!user ? (
                    <>
                      <button
                        onClick={() => {
                          setIsLoginModalOpen(true);
                          setIsUserMenuOpen(false);
                        }}
                        className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100"
                      >
                        Login
                      </button>
                      {/* You can add Register option here if desired */}
                      {/* <button
                        onClick={handleTrackOrder}
                        className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100"
                      >
                        Track Your Order
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        onClick={handleTrackOrder}
                        className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100"
                      >
                        Track Your Order
                      </button>
                      <button
                        onClick={handleLogout}
                        className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100"
                      >
                        Logout
                      </button>
                    </>
                  )}
                </motion.div>
              )}
            </AnimatePresence>
          </div> */}
        </div>
      </div>

      {/* Bottom Links (Desktop Only) */}
      <div className="hidden md:flex bg-black-100 py-2">
        <div className="container mx-auto flex justify-center space-x-6 text-sm text-white-700">
          {/* <NavLink to="/new" className="hover:text-white-800">
            New Arrivals
          </NavLink> */}
          <NavLink to="/shop" className="hover:text-white-800">
            Shop
          </NavLink>
          {/* <NavLink to="/tops" className="hover:text-white-800">
            Tops
          </NavLink>
          <NavLink to="/pants" className="hover:text-white-800">
            Bottoms
          </NavLink>
          <NavLink to="/caps" className="hover:text-white-800">
            Caps
          </NavLink>
          <NavLink to="/accessories" className="hover:text-white-800">
            Accessories
          </NavLink> */}
        </div>
      </div>

      {/* Mobile Menu */}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            ref={menuRef}
            className="fixed inset-0 bg-black text-black-600 p-4 z-20 overflow-y-auto"
            initial={{ y: "-100%" }}
            animate={{ y: 0 }}
            exit={{ y: "-100%" }}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
          >
            {/* Close Button */}
            <div className="flex justify-end">
              <X
                className="text-white-800 cursor-pointer"
                onClick={toggleMenu}
              />
            </div>
            {/* Navigation Links */}
            {/* <NavLink
              to="/new"
              className="block py-2 hover:text-white-800"
              onClick={toggleMenu}
            >
              New Arrivals
            </NavLink> */}
            <NavLink
              to="/shop"
              className="block py-2 hover:text-white-800"
              onClick={toggleMenu}
            >
              Shop
            </NavLink>
            {/* <NavLink
              to="/tops"
              className="block py-2 hover:text-white-800"
              onClick={toggleMenu}
            >
              Tops
            </NavLink>
            <NavLink
              to="/pants"
              className="block py-2 hover:text-white-800"
              onClick={toggleMenu}
            >
              Bottoms
            </NavLink>
            <NavLink
              to="/caps"
              className="block py-2 hover:text-white-800"
              onClick={toggleMenu}
            >
              Caps
            </NavLink>
            <NavLink
              to="/accessories"
              className="block py-2 hover:text-white-800"
              onClick={toggleMenu}
            >
              Accessories
            </NavLink> */}

            {/* User Options in Mobile Menu */}
            {/* <div className="mt-4 border-t pt-4">
              {!user ? (
                <>
                  <button
                    onClick={() => {
                      setIsLoginModalOpen(true);
                      setIsOpen(false);
                    }}
                    className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100"
                  >
                    Login
                  </button>
                  {/* Optionally, add Register here */}
                  {/* <button
                    onClick={handleTrackOrder}
                    className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100"
                  >
                    Track Your Order
                  </button>
                </>
              ) : (
                <>
                  <button
                    onClick={handleTrackOrder}
                    className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100"
                  >
                    Track Your Order
                  </button>
                  <button
                    onClick={handleLogout}
                    className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100"
                  >
                    Logout
                  </button>
                </>
              )}
            </div> */}
          </motion.div>
        )}
      </AnimatePresence>

      {/* Cart Drawer */}
      <AnimatePresence>
        {cartOpen && (
          <motion.div
            ref={cartRef}
            className="fixed top-0 right-0 w-full sm:w-96 h-full bg-blue-100 shadow-lg z-50"
            initial={{ x: "100%" }}
            animate={{ x: 0 }}
            exit={{ x: "100%" }}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
          >
            <div className="p-4">
              <div className="flex justify-between items-center">
                <h2 className="text-lg font-semibold">Your Cart</h2>
                <X
                  className="text-white-800 cursor-pointer"
                  onClick={() => setCartOpen(false)}
                />
              </div>
              <div className="mt-4">
                {cartItems.length === 0 ? (
                  <p className="text-center text-white-500 mt-10">
                    Your cart is empty.
                  </p>
                ) : (
                  cartItems.map((item, index) => (
                    <div
                      key={index}
                      className="flex items-center justify-between mt-4 border p-4 rounded-lg shadow-sm bg-white"
                    >
                      <img
                        src={item.image || "default-image-url"}
                        alt={item.itemName}
                        className="w-16 h-16 rounded-lg mr-4"
                      />
                      <div className="flex-1">
                        <p className="text-sm font-semibold text-white-700">
                          {item.itemName}
                        </p>
                        <p className="text-sm text-white-700 mt-2">
                          Quantity: {item.quantity}
                        </p>
                        <div className="flex items-center mt-2">
                          <MinusCircle
                            className="text-white-500 cursor-pointer"
                            onClick={() =>
                              updateCartItemQuantity(item.id, item.quantity - 1)
                            }
                          />
                          <span className="mx-2 text-white-700">
                            {item.quantity}
                          </span>
                          <PlusCircle
                            className="text-white-500 cursor-pointer"
                            onClick={() =>
                              updateCartItemQuantity(item.id, item.quantity + 1)
                            }
                          />
                        </div>
                      </div>
                      <div className="text-right">
                        <p className="text-sm font-semibold text-white-700">
                          Ksh{" "}
                          {item.retailPrice
                            ? (
                                parseFloat(item.retailPrice) * item.quantity
                              ).toFixed(2)
                            : "0.00"}
                        </p>
                        <Trash
                          className="text-red-500 cursor-pointer mt-2"
                          onClick={() => removeFromCart(item.id)}
                        />
                      </div>
                    </div>
                  ))
                )}
              </div>

              <div className="mt-6">
                <button
                  onClick={handleViewCart}
                  className="w-full bg-green-600 text-white py-2 rounded-md shadow hover:bg-green-700"
                >
                  View Cart
                </button>
                <button
                  onClick={handleCheckout}
                  className={`w-full bg-white-800 text-white py-2 mt-4 rounded-md shadow ${
                    cartItems.length === 0
                      ? "opacity-50 cursor-not-allowed"
                      : "hover:bg-white-900"
                  }`}
                  disabled={cartItems.length === 0}
                >
                  Proceed to Checkout
                </button>
                <button
                  onClick={clearCart}
                  className={`w-full mt-2 bg-red-500 text-white py-2 rounded-md shadow ${
                    cartItems.length === 0
                      ? "opacity-50 cursor-not-allowed"
                      : "hover:bg-red-600"
                  }`}
                  disabled={cartItems.length === 0}
                >
                  Clear Cart
                </button>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Login and Register Modals */}
      <LoginModal
        isOpen={isLoginModalOpen}
        onClose={() => setIsLoginModalOpen(false)}
        onLoginSuccess={onLoginSuccess}
        onOpenRegister={onOpenRegister}
      />

      <RegisterModal
        isOpen={isRegisterModalOpen}
        onClose={() => setIsRegisterModalOpen(false)}
        onOpenLogin={onOpenLogin}
      />
    </nav>
  );
};

export default Navbar;
